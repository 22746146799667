@charset "UTF-8";
/*!
 * Boosted v4.6.2 (https://boosted.orange.com)
 * Copyright 2014-2022 The Boosted Authors
 * Copyright 2014-2022 Orange
 * Licensed under MIT (https://github.com/orange-opensource/orange-boosted-bootstrap/blob/master/LICENSE)
 * This a fork of Bootstrap : Initial license below
 * Bootstrap v4.6.2 (https://getbootstrap.com)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.alert-success .alert-icon::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 125 125'%3e%3cpath fill='%2332c832' d='M62.5 0a62.5 62.5 0 100 125 62.5 62.5 0 000-125zm28 29.4c3.3 0 6 2.6 6 5.9a5.9 5.9 0 01-1.3 3.7L57.7 86a5.8 5.8 0 01-9.1 0L29.8 62.5c-.8-1-1.2-2.3-1.2-3.7a5.9 5.9 0 011.7-4.1l2.3-2.4a5.8 5.8 0 014.2-1.7 5.8 5.8 0 013.8 1.4L52 64.7 86.6 31a5.8 5.8 0 014-1.6z'/%3e%3c/svg%3e");
}

.alert-warning .alert-icon::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath fill='%23fc0' d='M15 0a15 15 0 100 30 15 15 0 000-30zm.15 5.39h.01c1.12 0 2 .95 1.92 2.06l-.63 10.43c0 .7-.58.97-1.29.97-.72 0-1.28-.27-1.28-.97l-.63-10.46c-.06-1.09.8-2.01 1.9-2.03zm-.3 15.33c.11 0 .21 0 .31.02 2.19.35 2.19 3.5 0 3.84-2.77.44-3.1-3.86-.3-3.86z'/%3e%3c/svg%3e");
}

.alert-danger .alert-icon::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 140 125'%3e%3cpath fill='%23cd3c14' d='M70.3 0c-5.8 0-10.8 3.1-13.5 7.8L2.3 101.3l-.2.2A15.6 15.6 0 0015.6 125H125a15.6 15.6 0 0013.5-23.5L83.8 7.8A15.6 15.6 0 0070.3 0zm19.2 50a6.4 6.4 0 014.4 1.9 6.4 6.4 0 010 9L79.4 75.6l15 15a6.4 6.4 0 010 9.2 6.4 6.4 0 01-4.5 1.9 6.4 6.4 0 01-4.6-2l-15-15-15 15a6.4 6.4 0 01-4.6 2 6.4 6.4 0 01-4.6-2 6.4 6.4 0 010-9l15-15L46.8 61a6.4 6.4 0 119-9.1l14.6 14.5L84.8 52a6.4 6.4 0 014.7-1.9z'/%3e%3c/svg%3e");
}

.alert-info .alert-icon::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 125 125'%3e%3cpath fill='%23527edb' d='M62.5 0a62.5 62.5 0 100 125 62.5 62.5 0 000-125zm0 14.7a11 11 0 110 22 11 11 0 010-22zM47.8 44.1h25.7v46.2c0 4.7 1.3 6.5 1.8 7.2.8 1 2.3 1.5 4.8 1.6h.8v3.8H47.8v-3.7h.8c2.3-.1 4-.8 5-2 .4-.4 1-2 1-7V57c0-4.8-.6-6.6-1.2-7.3-.8-1-2.4-1.5-4.9-1.6h-.7V44z'/%3e%3c/svg%3e");
}

:root {
  --blue: #527edb;
  --indigo: #a885d8;
  --purple: #a885d8;
  --pink: #ffb4e6;
  --red: #cd3c14;
  --orange: #ff7900;
  --orange-2: #f16e00;
  --yellow: #ffd200;
  --green: #32c832;
  --teal: #50be87;
  --cyan: #4bb4e6;
  --white: #fff;
  --gray: #999;
  --gray-dark: #595959;
  --primary: #ff7900;
  --secondary: #000;
  --success: #32c832;
  --info: #527edb;
  --warning: #fc0;
  --danger: #cd3c14;
  --light: #ccc;
  --dark: #000;
  --breakpoint-xs: 0;
  --breakpoint-sm: 480px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1024px;
  --breakpoint-xl: 1280px;
  --breakpoint-xxl: 1440px;
  --font-family-sans-serif: HelvNeueOrange/*rtl:insert:Arabic*/, Helvetica Neue, Helvetica, Arial, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* rtl:raw:
* {
  letter-spacing: 0 !important;
}
*/
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  display: block;
}

body {
  margin: 0;
  font-family: HelvNeueOrange/*rtl:insert:Arabic*/, Helvetica Neue, Helvetica, Arial, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-synthesis: none;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.125;
  color: #000;
  text-align: left;
  letter-spacing: -0.00625rem;
  background-color: #fff;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizespeed;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

.js-focus-visible :focus:not([data-focus-visible-added]),
.js-focus-visible .focus:not([data-focus-visible-added]) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1.25rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

ul {
  list-style-type: square;
}

li::marker {
  color: #f16e00;
  vertical-align: middle;
}

ol li::marker {
  color: inherit;
}

li li::marker {
  color: #999;
}

li li li::marker {
  color: #ccc;
}

li::before {
  color: #f16e00;
  vertical-align: text-top;
}

ol li::before {
  color: inherit;
}

li li::before {
  color: #999;
}

li li li::before {
  color: #ccc;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
em,
strong {
  font-weight: 700;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #000;
  text-decoration: underline;
  background-color: transparent;
  outline-offset: 0.25rem;
  transition: outline-offset 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  a {
    transition: none;
  }
}

a:focus {
  color: #ff7900;
  outline: 0.125rem solid;
  outline-offset: -0.125rem;
}

a:hover {
  color: #f16e00;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

var,
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  border-collapse: collapse;
}

caption {
  padding: 0.75rem 0;
  font-size: 2.125rem;
  font-weight: 700;
  color: #000;
  text-align: left;
  caption-side: top;
  background-color: #fff;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.375rem;
  font-weight: 700;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -0.125rem;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.125;
  letter-spacing: -0.00625rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}

[class*="display-"] {
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}

.display-1 {
  font-size: 1.875rem;
  line-height: 1.066667;
  letter-spacing: -0.05rem;
}

h1, .h1,
.display-2,
.display-3 {
  font-size: 1.5rem;
  line-height: 1.083333;
  letter-spacing: -0.0375rem;
}

.display-2 {
  letter-spacing: -0.03125rem;
}

h2, .h2,
.display-4 {
  font-size: 1.125rem;
  line-height: 1.111111;
  letter-spacing: -0.0125rem;
}

.lead {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.125;
  letter-spacing: -0.00625rem;
}

@media (min-width: 480px) {
  h1,
  .display-1,
  .display-2,
  .display-3 {
    line-height: 1;
  }
  .display-1 {
    font-size: 3.125rem;
    letter-spacing: -0.1rem;
  }
  .display-2 {
    font-size: 2.5rem;
    letter-spacing: -0.0625rem;
  }
  h1, .h1,
  .display-3 {
    font-size: 2.125rem;
    letter-spacing: -0.0625rem;
  }
  h2, .h2,
  .display-4 {
    font-size: 1.5rem;
    line-height: 1.083333;
    letter-spacing: -0.03125rem;
  }
  h3, .h3,
  h4, .h4,
  .lead {
    font-size: 1.125rem;
    line-height: 1.111111;
    letter-spacing: -0.0125rem;
  }
}

@media (min-width: 1024px) {
  .display-1 {
    font-size: 3.75rem;
    letter-spacing: -0.125rem;
  }
  .display-2 {
    font-size: 3.125rem;
    letter-spacing: -0.1rem;
  }
  .display-3 {
    font-size: 2.5rem;
    letter-spacing: -0.0625rem;
  }
  .display-4 {
    font-size: 2.125rem;
    letter-spacing: -0.0625rem;
  }
  h1, .h1 {
    font-size: 2.125rem;
    line-height: 1;
    letter-spacing: -0.0625rem;
  }
  h2, .h2 {
    font-size: 1.875rem;
    line-height: 1.066667;
    letter-spacing: -0.05rem;
  }
  h3, .h3 {
    font-size: 1.5rem;
    line-height: 1.083333;
    letter-spacing: -0.03125rem;
  }
  h4, .h4 {
    font-size: 1.25rem;
    line-height: 1.1;
    letter-spacing: -0.025rem;
  }
  h5, .h5 {
    font-size: 1.125rem;
    line-height: 1.111111;
    letter-spacing: -0.0125rem;
  }
  h6, .h6 {
    font-size: 1rem;
    line-height: 1.125;
    letter-spacing: -0.00625rem;
  }
  .lead {
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: -0.015625rem;
  }
}

hr {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 0;
  border-top: 0.0625rem solid #eee;
}

small,
.small {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.142857;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #ff7900;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.3125rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: -0.015625rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875rem;
  line-height: 1.142857;
  color: #666;
  letter-spacing: -0.00625rem;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.625rem;
  background-color: #fff;
  border: 0.125rem solid #000;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.625rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #333;
}

var,
code {
  font-size: 87.5%;
  font-style: normal;
  color: #333;
  word-wrap: break-word;
}

a > var, a >
code {
  color: inherit;
}

kbd {
  padding: 0.0625rem 0.0625rem;
  font-size: 87.5%;
  color: #000;
  background-color: #eee;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  line-height: 1.25;
  color: #333;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xs,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 0.3125rem;
  padding-left: 0.3125rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container,
  .container-fluid,
  .container-xs,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}


.container-fluid,
.container-xs,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  max-width: 97.5%;
}

@media (min-width: 480px) {
  
  .container-fluid,
  .container-xs,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 97.5%;
  }
}

@media (min-width: 768px) {
  
  .container-fluid,
  .container-xs,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 96.875%;
  }
}

@media (min-width: 1024px) {
  
  .container-fluid,
  .container-xs,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 93.75%;
  }
}

@media (min-width: 1280px) {
  
  .container-fluid,
  .container-xs,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 93.75%;
  }
}

@media (min-width: 1440px) {
  
  .container-fluid,
  .container-xs,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 91.66666%;
  }
}

.container {
  max-width: 312px;
}

@media (min-width: 480px) {
  .container, .container-sm {
    max-width: 468px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 744px;
  }
}

@media (min-width: 1024px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1280px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1200px;
  }
}

@media (min-width: 1440px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.3125rem;
  margin-left: -0.3125rem;
}

@media (min-width: 768px) {
  .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 0.3125rem;
  padding-left: 0.3125rem;
}

@media (min-width: 768px) {
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
  .col-xxl-auto {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 480px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1024px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1280px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1440px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1.25rem;
  color: #000;
  background-color: #fff;
}

.table tr {
  border-bottom: 0.0625rem solid #ccc;
}

.table th,
.table td {
  padding: 0.875rem 0.625rem calc(0.875rem + 1px);
  line-height: 1.25;
  vertical-align: top;
}

.table thead tr {
  border-bottom-width: 0.125rem;
}

.table tbody th {
  font-weight: 400;
}

.table img,
.table svg,
.table [class^="svg-"],
.table [class^="icon-"],
.table [class*=" icon-"] {
  display: inline-block;
  width: 1.875rem;
  height: 1.875rem;
  margin: -0.625rem 0.625rem -0.375rem 0;
  font-size: 1.875rem;
  vertical-align: middle;
}

.table [class^="svg-"]::after {
  width: inherit;
  height: inherit;
}

.table .selected * {
  background-color: #ddd;
}

.table .custom-control {
  display: inline-block;
  margin: -.25rem 0 0;
  line-height: 1;
  vertical-align: middle;
}

.table tbody + tbody {
  border-top: 0.125rem solid #ccc;
}

.table-sm th,
.table-sm td {
  padding: 0.5625rem 0.625rem calc(0.5625rem + 1px);
}

.table-hover tbody tr:hover {
  color: #000;
  background-color: #eee;
}

.table-dark {
  color: #fff;
  background-color: #000;
}

.table-dark tr,
.table-dark th {
  border-color: #666;
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: #333;
}

@media (max-width: 479.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1023.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1279.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1439.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.has-checkbox tr > :first-child {
  width: 1.25rem;
}

label.disabled,
[disabled] label {
  color: #ccc;
}

.is-required:not(.form-group)::after {
  margin-left: 0.1875rem;
  font-weight: 700;
  color: #f16e00;
  content: "*";
}

.form-control {
  display: block;
  width: 100%;
  height: 2.5rem;
  padding: calc(0.5rem - 1px) 0.625rem calc(0.5rem + 1px);
  font-family: HelvNeueOrange/*rtl:insert:Arabic*/, Helvetica Neue, Helvetica, Arial, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.125rem solid #ccc;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #000;
  background-color: #fff;
  border-color: #000;
  outline: 0;
}

.form-control::placeholder {
  color: #666;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  color: #666;
  background-color: #eee;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
}

select.form-control:focus::-ms-value {
  color: #000;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.25;
}

.col-form-label-lg {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  font-size: 1.125rem;
  line-height: 1.111111;
}

.col-form-label-sm {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  font-size: 0.875rem;
  line-height: 1.142857;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.25;
  color: #000;
  background-color: transparent;
  border: solid transparent;
  border-width: 0.125rem 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: 1.875rem;
  padding: 0.3125rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.142857;
}

.form-control-lg {
  height: 3.125rem;
  padding: 0.8125rem 1.25rem;
  font-size: 1.125rem;
  line-height: 1.111111;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1.25rem;
}

.form-text {
  display: block;
  margin-top: 0.625rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
  margin-bottom: 0.625rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.15rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #666;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 125 125'%3e%3cpath fill='%2332c832' d='M62.5 0a62.5 62.5 0 100 125 62.5 62.5 0 000-125zm28 29.4c3.3 0 6 2.6 6 5.9a5.9 5.9 0 01-1.3 3.7L57.7 86a5.8 5.8 0 01-9.1 0L29.8 62.5c-.8-1-1.2-2.3-1.2-3.7a5.9 5.9 0 011.7-4.1l2.3-2.4a5.8 5.8 0 014.2-1.7 5.8 5.8 0 013.8 1.4L52 64.7 86.6 31a5.8 5.8 0 014-1.6z'/%3e%3c/svg%3e") no-repeat right 0.625rem center/1.25rem;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.625rem 0.625rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.125;
  color: #000;
  background-color: #32c832;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 2.5rem !important;
  background-position: right 1.25rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 2.25rem;
  background-position: top calc(0.3125em + 0.25rem) right calc(0.3125em + 0.25rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  padding-right: calc(0.75em + 2.28125rem) !important;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  color: #32c832 !important;
  border-color: currentcolor !important;
  transition: none;
}

.was-validated .custom-control-input:valid:active ~ .custom-control-label::before, .custom-control-input.is-valid:active ~ .custom-control-label::before {
  background: currentcolor !important;
}

.was-validated .custom-checkbox .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-checkbox .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background: currentcolor !important;
}

.was-validated .custom-radio .custom-control-input:valid ~ .custom-control-label::after, .custom-radio .custom-control-input.is-valid ~ .custom-control-label::after {
  border-color: currentcolor !important;
  transition: none;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.625rem;
  font-size: 0.875rem;
  font-weight: 700;
}

.invalid-feedback::before {
  display: inline-block;
  flex-shrink: 0;
  align-self: center;
  width: 0.9375rem;
  height: 0.9375rem;
  margin-right: 0.3125rem;
  content: "";
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 140 125'%3e%3cpath fill='%23cd3c14' d='M70.3 0c-5.8 0-10.8 3.1-13.5 7.8L2.3 101.3l-.2.2A15.6 15.6 0 0015.6 125H125a15.6 15.6 0 0013.5-23.5L83.8 7.8A15.6 15.6 0 0070.3 0zm19.2 50a6.4 6.4 0 014.4 1.9 6.4 6.4 0 010 9L79.4 75.6l15 15a6.4 6.4 0 010 9.2 6.4 6.4 0 01-4.5 1.9 6.4 6.4 0 01-4.6-2l-15-15-15 15a6.4 6.4 0 01-4.6 2 6.4 6.4 0 01-4.6-2 6.4 6.4 0 010-9l15-15L46.8 61a6.4 6.4 0 119-9.1l14.6 14.5L84.8 52a6.4 6.4 0 014.7-1.9z'/%3e%3c/svg%3e") no-repeat;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.625rem 0.625rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.125;
  color: #fff;
  background-color: #cd3c14;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: flex;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #cd3c14;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #000;
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 2.5rem !important;
  background-position: right 1.25rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 2.25rem;
  background-position: top calc(0.3125em + 0.25rem) right calc(0.3125em + 0.25rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #cd3c14;
  padding-right: calc(0.75em + 2.28125rem) !important;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #000;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  color: #cd3c14 !important;
  border-color: currentcolor !important;
  transition: none;
}

.was-validated .custom-control-input:invalid:active ~ .custom-control-label::before, .custom-control-input.is-invalid:active ~ .custom-control-label::before {
  background: currentcolor !important;
}

.was-validated .custom-checkbox .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-checkbox .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background: currentcolor !important;
}

.was-validated .custom-radio .custom-control-input:invalid ~ .custom-control-label::after, .custom-radio .custom-control-input.is-invalid ~ .custom-control-label::after {
  border-color: currentcolor !important;
  transition: none;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #cd3c14;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #000;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 480px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  font-family: HelvNeueOrange/*rtl:insert:Arabic*/, Helvetica Neue, Helvetica, Arial, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 700;
  color: #000;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 0.125rem solid transparent;
  padding: calc(0.5rem - 1px) 1.125rem calc(0.5rem + 1px);
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0;
  transition: outline-offset 0.15s ease-in-out;
  outline-offset: 0.25rem;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #000;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0.125rem solid;
  outline-offset: -0.125rem;
}

.btn.disabled, .btn:disabled {
  opacity: 1;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn [class*="svg-"],
.btn [class*="icon-"] {
  display: inline-block;
}

.btn:not(.btn-icon) [class*="svg-"],
.btn:not(.btn-icon) [class*="icon-"] {
  position: relative;
  top: 1px;
  margin-right: 0.375rem;
}

.btn [class*="icon-"]::before {
  font-size: 1.25rem;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary,
.btn-warning {
  color: #000;
  background-color: #ff7900;
  border-color: #ff7900;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary.focus,
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
  outline-color: #000;
}

.btn-primary.disabled, .btn-primary:disabled,
.btn-warning.disabled,
.btn-warning:disabled {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show >
.btn-warning.dropdown-toggle {
  color: #000;
  background-color: #fff;
  border-color: #000;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled).active.focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled).active.focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
  outline-color: #000;
}

.btn-primary.btn-inverse,
.btn-warning.btn-inverse {
  color: #000;
  background-color: #ff7900;
  border-color: #ff7900;
}

.btn-primary.btn-inverse:hover, .btn-primary.btn-inverse:focus, .btn-primary.btn-inverse.focus,
.btn-warning.btn-inverse:hover,
.btn-warning.btn-inverse:focus,
.btn-warning.btn-inverse.focus {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  outline-color: #fff;
}

.btn-primary.btn-inverse.disabled, .btn-primary.btn-inverse:disabled,
.btn-warning.btn-inverse.disabled,
.btn-warning.btn-inverse:disabled {
  color: #000;
  background-color: #666;
  border-color: #666;
}

.btn-primary.btn-inverse:not(:disabled):not(.disabled):active, .btn-primary.btn-inverse:not(:disabled):not(.disabled).active,
.show > .btn-primary.btn-inverse.dropdown-toggle,
.btn-warning.btn-inverse:not(:disabled):not(.disabled):active,
.btn-warning.btn-inverse:not(:disabled):not(.disabled).active,
.show >
.btn-warning.btn-inverse.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #fff;
}

.btn-primary.btn-inverse:not(:disabled):not(.disabled).active:focus, .btn-primary.btn-inverse:not(:disabled):not(.disabled).active.focus,
.btn-warning.btn-inverse:not(:disabled):not(.disabled).active:focus,
.btn-warning.btn-inverse:not(:disabled):not(.disabled).active.focus {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  outline-color: #fff;
}

.btn-secondary,
.btn-light {
  color: #000;
  background-color: #fff;
  border-color: #000;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary.focus,
.btn-light:hover,
.btn-light:focus,
.btn-light.focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
  outline-color: #000;
}

.btn-secondary.disabled, .btn-secondary:disabled,
.btn-light.disabled,
.btn-light:disabled {
  color: #ccc;
  background-color: #fff;
  border-color: #ccc;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle,
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show >
.btn-light.dropdown-toggle {
  color: #000;
  background-color: #ff7900;
  border-color: #ff7900;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled).active.focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled).active.focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
  outline-color: #000;
}

.btn-secondary.btn-inverse,
.btn-light.btn-inverse {
  color: #fff;
  background-color: #000;
  border-color: #fff;
}

.btn-secondary.btn-inverse:hover, .btn-secondary.btn-inverse:focus, .btn-secondary.btn-inverse.focus,
.btn-light.btn-inverse:hover,
.btn-light.btn-inverse:focus,
.btn-light.btn-inverse.focus {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  outline-color: #fff;
}

.btn-secondary.btn-inverse.disabled, .btn-secondary.btn-inverse:disabled,
.btn-light.btn-inverse.disabled,
.btn-light.btn-inverse:disabled {
  color: #666;
  background-color: transparent;
  border-color: #666;
}

.btn-secondary.btn-inverse:not(:disabled):not(.disabled):active, .btn-secondary.btn-inverse:not(:disabled):not(.disabled).active,
.show > .btn-secondary.btn-inverse.dropdown-toggle,
.btn-light.btn-inverse:not(:disabled):not(.disabled):active,
.btn-light.btn-inverse:not(:disabled):not(.disabled).active,
.show >
.btn-light.btn-inverse.dropdown-toggle {
  color: #000;
  background-color: #ff7900;
  border-color: #ff7900;
}

.btn-secondary.btn-inverse:not(:disabled):not(.disabled).active:focus, .btn-secondary.btn-inverse:not(:disabled):not(.disabled).active.focus,
.btn-light.btn-inverse:not(:disabled):not(.disabled).active:focus,
.btn-light.btn-inverse:not(:disabled):not(.disabled).active.focus {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  outline-color: #fff;
}

.btn-success {
  color: #000;
  background-color: #32c832;
  border-color: #32c832;
}

.btn-success:hover, .btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
  outline-color: #000;
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #000;
  background-color: #ff7900;
  border-color: #ff7900;
}

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled).active.focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
  outline-color: #000;
}

.btn-success.btn-inverse {
  color: #000;
  background-color: #32c832;
  border-color: #32c832;
}

.btn-success.btn-inverse:hover, .btn-success.btn-inverse:focus, .btn-success.btn-inverse.focus {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  outline-color: #fff;
}

.btn-success.btn-inverse.disabled, .btn-success.btn-inverse:disabled {
  color: #000;
  background-color: #666;
  border-color: #666;
}

.btn-success.btn-inverse:not(:disabled):not(.disabled):active, .btn-success.btn-inverse:not(:disabled):not(.disabled).active,
.show > .btn-success.btn-inverse.dropdown-toggle {
  color: #000;
  background-color: #ff7900;
  border-color: #ff7900;
}

.btn-success.btn-inverse:not(:disabled):not(.disabled).active:focus, .btn-success.btn-inverse:not(:disabled):not(.disabled).active.focus {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  outline-color: #fff;
}

.btn-info,
.btn-dark {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-info:hover, .btn-info:focus, .btn-info.focus,
.btn-dark:hover,
.btn-dark:focus,
.btn-dark.focus {
  color: #000;
  background-color: #fff;
  border-color: #000;
  outline-color: #000;
}

.btn-info.disabled, .btn-info:disabled,
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle,
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show >
.btn-dark.dropdown-toggle {
  color: #000;
  background-color: #ff7900;
  border-color: #ff7900;
}

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled).active.focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled).active.focus {
  color: #000;
  background-color: #fff;
  border-color: #000;
  outline-color: #000;
}

.btn-info.btn-inverse,
.btn-dark.btn-inverse {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-info.btn-inverse:hover, .btn-info.btn-inverse:focus, .btn-info.btn-inverse.focus,
.btn-dark.btn-inverse:hover,
.btn-dark.btn-inverse:focus,
.btn-dark.btn-inverse.focus {
  color: #fff;
  background-color: #000;
  border-color: #fff;
  outline-color: #fff;
}

.btn-info.btn-inverse.disabled, .btn-info.btn-inverse:disabled,
.btn-dark.btn-inverse.disabled,
.btn-dark.btn-inverse:disabled {
  color: #000;
  background-color: #666;
  border-color: #666;
}

.btn-info.btn-inverse:not(:disabled):not(.disabled):active, .btn-info.btn-inverse:not(:disabled):not(.disabled).active,
.show > .btn-info.btn-inverse.dropdown-toggle,
.btn-dark.btn-inverse:not(:disabled):not(.disabled):active,
.btn-dark.btn-inverse:not(:disabled):not(.disabled).active,
.show >
.btn-dark.btn-inverse.dropdown-toggle {
  color: #000;
  background-color: #ff7900;
  border-color: #ff7900;
}

.btn-info.btn-inverse:not(:disabled):not(.disabled).active:focus, .btn-info.btn-inverse:not(:disabled):not(.disabled).active.focus,
.btn-dark.btn-inverse:not(:disabled):not(.disabled).active:focus,
.btn-dark.btn-inverse:not(:disabled):not(.disabled).active.focus {
  color: #fff;
  background-color: #000;
  border-color: #fff;
  outline-color: #fff;
}

.btn-danger {
  color: #fff;
  background-color: #cd3c14;
  border-color: #cd3c14;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
  outline-color: #000;
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #000;
  background-color: #ff7900;
  border-color: #ff7900;
}

.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled).active.focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
  outline-color: #000;
}

.btn-danger.btn-inverse {
  color: #fff;
  background-color: #cd3c14;
  border-color: #cd3c14;
}

.btn-danger.btn-inverse:hover, .btn-danger.btn-inverse:focus, .btn-danger.btn-inverse.focus {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  outline-color: #fff;
}

.btn-danger.btn-inverse.disabled, .btn-danger.btn-inverse:disabled {
  color: #000;
  background-color: #666;
  border-color: #666;
}

.btn-danger.btn-inverse:not(:disabled):not(.disabled):active, .btn-danger.btn-inverse:not(:disabled):not(.disabled).active,
.show > .btn-danger.btn-inverse.dropdown-toggle {
  color: #000;
  background-color: #ff7900;
  border-color: #ff7900;
}

.btn-danger.btn-inverse:not(:disabled):not(.disabled).active:focus, .btn-danger.btn-inverse:not(:disabled):not(.disabled).active.focus {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  outline-color: #fff;
}

.btn-link {
  color: #000;
  text-decoration: underline;
  border-color: transparent !important;
}

.btn-link.btn-inverse {
  color: #fff;
}

.btn-link:hover {
  color: #f16e00;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #ccc;
  pointer-events: none;
}

.btn-icon {
  justify-content: center;
  min-width: 2.5rem;
  padding: 0.5rem;
  line-height: .875 !important;
}

.btn-social {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  background: transparent no-repeat center/1.25rem;
  border: 0.125rem solid #000;
  border-radius: 50%;
  transition: none;
}

.btn-social:hover, .btn-social:focus {
  outline: 0;
}

.btn-facebook, .btn-facebook.btn-inverse:active, .btn-facebook.btn-inverse.active {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath fill='%23000' d='M19 6h5V0h-5c-4 0-7 3-7 7v3H8v6h4v16h6V16h5l1-6h-6V7l1-1z'%3e%3c/path%3e%3c/svg%3e");
}

.btn-facebook.btn-inverse:active, .btn-facebook.btn-inverse.active {
  border-color: #fff;
}

.btn-facebook:hover, .btn-facebook:focus, .btn-facebook:active, .btn-facebook.active, .btn-facebook.btn-inverse {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath fill='%23fff' d='M19 6h5V0h-5c-4 0-7 3-7 7v3H8v6h4v16h6V16h5l1-6h-6V7l1-1z'%3e%3c/path%3e%3c/svg%3e");
  border-color: #fff;
  outline-color: #fff;
}

.btn-facebook.btn-inverse {
  background-color: #000;
}

.btn-facebook:hover, .btn-facebook:focus {
  background-color: #3b5998;
  border-color: #3b5998;
  outline-color: #3b5998;
}

.btn-facebook:active, .btn-facebook.active {
  background-color: #000;
  border-color: #000;
  outline-color: #000;
}

.btn-twitter, .btn-twitter.btn-inverse:active, .btn-twitter.btn-inverse.active {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath fill='%23000' d='M32 7a13 13 0 01-3.8 1.1 6.6 6.6 0 003-3.6c-1.4.7-2.8 1.3-4.3 1.6a6.6 6.6 0 00-11.1 6A18.6 18.6 0 012.2 5a6.6 6.6 0 002 8.9c-1 0-2-.4-3-.9v.1c0 3.2 2.4 5.9 5.4 6.5a6.6 6.6 0 01-3 0 6.6 6.6 0 006.1 4.6A13.2 13.2 0 010 27.1a18.6 18.6 0 0028.7-16.6C30 9.5 31.1 8.4 32 7z'/%3e%3c/svg%3e");
}

.btn-twitter.btn-inverse:active, .btn-twitter.btn-inverse.active {
  border-color: #fff;
}

.btn-twitter:hover, .btn-twitter:focus, .btn-twitter:active, .btn-twitter.active, .btn-twitter.btn-inverse {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath fill='%23fff' d='M32 7a13 13 0 01-3.8 1.1 6.6 6.6 0 003-3.6c-1.4.7-2.8 1.3-4.3 1.6a6.6 6.6 0 00-11.1 6A18.6 18.6 0 012.2 5a6.6 6.6 0 002 8.9c-1 0-2-.4-3-.9v.1c0 3.2 2.4 5.9 5.4 6.5a6.6 6.6 0 01-3 0 6.6 6.6 0 006.1 4.6A13.2 13.2 0 010 27.1a18.6 18.6 0 0028.7-16.6C30 9.5 31.1 8.4 32 7z'/%3e%3c/svg%3e");
  border-color: #fff;
  outline-color: #fff;
}

.btn-twitter.btn-inverse {
  background-color: #000;
}

.btn-twitter:hover, .btn-twitter:focus {
  background-color: #1da1f2;
  border-color: #1da1f2;
  outline-color: #1da1f2;
}

.btn-twitter:active, .btn-twitter.active {
  background-color: #000;
  border-color: #000;
  outline-color: #000;
}

.btn-instagram, .btn-instagram.btn-inverse:active, .btn-instagram.btn-inverse.active {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath fill='%23000' d='M16 2.9h6.5c1.5.1 2.4.4 3 .6a5 5 0 011.8 1.2c.5.6.9 1.1 1.2 1.9.2.5.4 1.4.5 3a112.7 112.7 0 01-.5 15.8 5 5 0 01-1.2 1.9c-.6.5-1.1.9-1.9 1.2-.5.2-1.4.4-3 .5a112.7 112.7 0 01-15.8-.5 5 5 0 01-1.9-1.2 5 5 0 01-1.2-1.9c-.2-.5-.4-1.4-.5-3a112.7 112.7 0 01.5-15.8 5 5 0 011.2-1.9c.6-.5 1.1-.9 1.9-1.2C7 3.3 8 3 9.6 3l6.4-.1zM16 0H9.4C7.7.3 6.5.5 5.5.9s-2 1-2.8 1.9c-1 .9-1.5 1.8-1.9 2.8-.4 1-.6 2.2-.7 3.9a117.6 117.6 0 00.7 17c.5 1.1 1 2 1.9 3 .9.8 1.8 1.4 2.8 1.8 1 .4 2.2.6 3.9.7a117.2 117.2 0 0017-.7c1.1-.4 2-1 2.9-1.9s1.4-1.8 1.8-2.8c.4-1 .7-2.2.8-3.9a117.2 117.2 0 00-.8-17A7.8 7.8 0 0026.4.8c-1-.5-2.1-.7-3.8-.8L16 0z'/%3e%3cpath fill='%23000' d='M16 7.8a8.2 8.2 0 100 16.4 8.2 8.2 0 000-16.4zm0 13.5a5.3 5.3 0 110-10.6 5.3 5.3 0 010 10.6zM26.5 7.5a2 2 0 11-3.9 0 2 2 0 013.9 0z'/%3e%3c/svg%3e");
}

.btn-instagram.btn-inverse:active, .btn-instagram.btn-inverse.active {
  border-color: #fff;
}

.btn-instagram:hover, .btn-instagram:focus, .btn-instagram:active, .btn-instagram.active, .btn-instagram.btn-inverse {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath fill='%23fff' d='M16 2.9h6.5c1.5.1 2.4.4 3 .6a5 5 0 011.8 1.2c.5.6.9 1.1 1.2 1.9.2.5.4 1.4.5 3a112.7 112.7 0 01-.5 15.8 5 5 0 01-1.2 1.9c-.6.5-1.1.9-1.9 1.2-.5.2-1.4.4-3 .5a112.7 112.7 0 01-15.8-.5 5 5 0 01-1.9-1.2 5 5 0 01-1.2-1.9c-.2-.5-.4-1.4-.5-3a112.7 112.7 0 01.5-15.8 5 5 0 011.2-1.9c.6-.5 1.1-.9 1.9-1.2C7 3.3 8 3 9.6 3l6.4-.1zM16 0H9.4C7.7.3 6.5.5 5.5.9s-2 1-2.8 1.9c-1 .9-1.5 1.8-1.9 2.8-.4 1-.6 2.2-.7 3.9a117.6 117.6 0 00.7 17c.5 1.1 1 2 1.9 3 .9.8 1.8 1.4 2.8 1.8 1 .4 2.2.6 3.9.7a117.2 117.2 0 0017-.7c1.1-.4 2-1 2.9-1.9s1.4-1.8 1.8-2.8c.4-1 .7-2.2.8-3.9a117.2 117.2 0 00-.8-17A7.8 7.8 0 0026.4.8c-1-.5-2.1-.7-3.8-.8L16 0z'/%3e%3cpath fill='%23fff' d='M16 7.8a8.2 8.2 0 100 16.4 8.2 8.2 0 000-16.4zm0 13.5a5.3 5.3 0 110-10.6 5.3 5.3 0 010 10.6zM26.5 7.5a2 2 0 11-3.9 0 2 2 0 013.9 0z'/%3e%3c/svg%3e");
  border-color: #fff;
  outline-color: #fff;
}

.btn-instagram.btn-inverse {
  background-color: #000;
}

.btn-instagram:hover, .btn-instagram:focus {
  background-color: #e1306c;
  border-color: #e1306c;
  outline-color: #e1306c;
}

.btn-instagram:active, .btn-instagram.active {
  background-color: #000;
  border-color: #000;
  outline-color: #000;
}

.btn-youtube, .btn-youtube.btn-inverse:active, .btn-youtube.btn-inverse.active {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath fill='%23000' d='M31.7 9.6s-.3-2.2-1.3-3.2c-1.2-1.3-2.6-1.3-3.2-1.3-4.5-.4-11.2-.4-11.2-.4s-6.7 0-11.2.4c-.6 0-2 0-3.2 1.3C.6 7.4.3 9.6.3 9.6S0 12.2 0 14.8v2.4c0 2.6.3 5.2.3 5.2s.3 2.2 1.3 3.2c1.2 1.2 2.8 1.2 3.5 1.3 2.6.3 11 .4 11 .4s6.6 0 11.1-.4c.6 0 2 0 3.2-1.3 1-1 1.3-3.2 1.3-3.2s.3-2.6.3-5.2v-2.4c0-2.6-.3-5.2-.3-5.2zm-19 10.5v-9l8.6 4.6-8.6 4.4z'/%3e%3c/svg%3e");
}

.btn-youtube.btn-inverse:active, .btn-youtube.btn-inverse.active {
  border-color: #fff;
}

.btn-youtube:hover, .btn-youtube:focus, .btn-youtube:active, .btn-youtube.active, .btn-youtube.btn-inverse {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath fill='%23fff' d='M31.7 9.6s-.3-2.2-1.3-3.2c-1.2-1.3-2.6-1.3-3.2-1.3-4.5-.4-11.2-.4-11.2-.4s-6.7 0-11.2.4c-.6 0-2 0-3.2 1.3C.6 7.4.3 9.6.3 9.6S0 12.2 0 14.8v2.4c0 2.6.3 5.2.3 5.2s.3 2.2 1.3 3.2c1.2 1.2 2.8 1.2 3.5 1.3 2.6.3 11 .4 11 .4s6.6 0 11.1-.4c.6 0 2 0 3.2-1.3 1-1 1.3-3.2 1.3-3.2s.3-2.6.3-5.2v-2.4c0-2.6-.3-5.2-.3-5.2zm-19 10.5v-9l8.6 4.6-8.6 4.4z'/%3e%3c/svg%3e");
  border-color: #fff;
  outline-color: #fff;
}

.btn-youtube.btn-inverse {
  background-color: #000;
}

.btn-youtube:hover, .btn-youtube:focus {
  background-color: #f00;
  border-color: #f00;
  outline-color: #f00;
}

.btn-youtube:active, .btn-youtube.active {
  background-color: #000;
  border-color: #000;
  outline-color: #000;
}

.btn-linkedin {
  background-position: center 40%;
}

.btn-linkedin, .btn-linkedin.btn-inverse:active, .btn-linkedin.btn-inverse.active {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath fill='%23000' d='M12 12h5.5v2.8h.1a6.1 6.1 0 015.5-2.8c5.8 0 6.9 3.6 6.9 8.4V30h-5.8v-8.5c0-2 0-4.7-3-4.7s-3.4 2.2-3.4 4.5V30H12V12zM2 12h6v18H2V12zm6-5a3 3 0 11-6 0 3 3 0 016 0z'/%3e%3c/svg%3e");
}

.btn-linkedin.btn-inverse:active, .btn-linkedin.btn-inverse.active {
  border-color: #fff;
}

.btn-linkedin:hover, .btn-linkedin:focus, .btn-linkedin:active, .btn-linkedin.active, .btn-linkedin.btn-inverse {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath fill='%23fff' d='M12 12h5.5v2.8h.1a6.1 6.1 0 015.5-2.8c5.8 0 6.9 3.6 6.9 8.4V30h-5.8v-8.5c0-2 0-4.7-3-4.7s-3.4 2.2-3.4 4.5V30H12V12zM2 12h6v18H2V12zm6-5a3 3 0 11-6 0 3 3 0 016 0z'/%3e%3c/svg%3e");
  border-color: #fff;
  outline-color: #fff;
}

.btn-linkedin.btn-inverse {
  background-color: #000;
}

.btn-linkedin:hover, .btn-linkedin:focus {
  background-color: #0077b5;
  border-color: #0077b5;
  outline-color: #0077b5;
}

.btn-linkedin:active, .btn-linkedin.active {
  background-color: #000;
  border-color: #000;
  outline-color: #000;
}

.btn-whatsapp, .btn-whatsapp.btn-inverse:active, .btn-whatsapp.btn-inverse.active {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath fill='%23000' d='M27.3 4.7a15.9 15.9 0 00-25 19.1L.1 32l8.4-2.2A15.9 15.9 0 0027.3 4.7zM16 29c-2.4 0-4.7-.6-6.7-1.8l-.5-.3-5 1.3 1.3-4.8-.3-.5A13.2 13.2 0 1116.1 29zm7.2-9.8l-2.7-1.3c-.3-.1-.6-.2-1 .2l-1.2 1.5c-.2.3-.4.3-.8.1s-1.7-.6-3.2-2c-1.2-1-2-2.3-2.2-2.7s0-.6.2-.8l.6-.7.4-.6v-.7l-1.3-3c-.3-.7-.6-.6-.9-.7h-.7c-.2 0-.7.1-1.1.5C9 9.4 8 10.4 8 12.3s1.4 3.9 1.6 4.1c.2.3 2.8 4.3 6.8 6l2.3.9c.9.3 1.8.2 2.4.1.8-.1 2.4-1 2.7-1.9s.4-1.7.3-1.9l-.8-.4z'/%3e%3c/svg%3e");
}

.btn-whatsapp.btn-inverse:active, .btn-whatsapp.btn-inverse.active {
  border-color: #fff;
}

.btn-whatsapp:hover, .btn-whatsapp:focus, .btn-whatsapp:active, .btn-whatsapp.active, .btn-whatsapp.btn-inverse {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath fill='%23fff' d='M27.3 4.7a15.9 15.9 0 00-25 19.1L.1 32l8.4-2.2A15.9 15.9 0 0027.3 4.7zM16 29c-2.4 0-4.7-.6-6.7-1.8l-.5-.3-5 1.3 1.3-4.8-.3-.5A13.2 13.2 0 1116.1 29zm7.2-9.8l-2.7-1.3c-.3-.1-.6-.2-1 .2l-1.2 1.5c-.2.3-.4.3-.8.1s-1.7-.6-3.2-2c-1.2-1-2-2.3-2.2-2.7s0-.6.2-.8l.6-.7.4-.6v-.7l-1.3-3c-.3-.7-.6-.6-.9-.7h-.7c-.2 0-.7.1-1.1.5C9 9.4 8 10.4 8 12.3s1.4 3.9 1.6 4.1c.2.3 2.8 4.3 6.8 6l2.3.9c.9.3 1.8.2 2.4.1.8-.1 2.4-1 2.7-1.9s.4-1.7.3-1.9l-.8-.4z'/%3e%3c/svg%3e");
  border-color: #fff;
  outline-color: #fff;
}

.btn-whatsapp.btn-inverse {
  background-color: #000;
}

.btn-whatsapp:hover, .btn-whatsapp:focus {
  background-color: #25d366;
  border-color: #25d366;
  outline-color: #25d366;
}

.btn-whatsapp:active, .btn-whatsapp.active {
  background-color: #000;
  border-color: #000;
  outline-color: #000;
}

.btn-mail {
  background-size: 1.5rem;
}

.btn-mail, .btn-mail.btn-inverse:active, .btn-mail.btn-inverse.active {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath fill='%23000' d='M3.2 14.3c0 9.5 0 9 .2 9.5.3.8 1 1.4 1.7 1.7l12.2.1h11.5v-8.8c0-9.3 0-8.9-.2-9.3-.2-.7-.7-1.2-1.3-1.6l-.8-.3H3.2v8.7zm22.9-2.4a246.2 246.2 0 01-4.9 4.7l-.8.7-.5.6-.7.6c-.6.6-1 .9-1.3 1a4 4 0 01-1.8.5 4 4 0 01-2.4-.6 13 13 0 01-1.9-1.7l-2.4-2.4-.6-.6-1.4-1.3L6.1 12l-.5-.5V8.9l.6.5L7.9 11l1.4 1.4 1.3 1.2 1.3 1.3a195 195 0 012.6 2.4c.4.3 1 .5 1.6.4.5 0 1-.1 1.4-.4L19 16l1-1 1-1a214.7 214.7 0 012.2-2l1-1 2-2 .2-.2v2.8l-.3.3z'/%3e%3c/svg%3e");
}

.btn-mail.btn-inverse:active, .btn-mail.btn-inverse.active {
  border-color: #fff;
}

.btn-mail:hover, .btn-mail:focus, .btn-mail:active, .btn-mail.active, .btn-mail.btn-inverse {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath fill='%23fff' d='M3.2 14.3c0 9.5 0 9 .2 9.5.3.8 1 1.4 1.7 1.7l12.2.1h11.5v-8.8c0-9.3 0-8.9-.2-9.3-.2-.7-.7-1.2-1.3-1.6l-.8-.3H3.2v8.7zm22.9-2.4a246.2 246.2 0 01-4.9 4.7l-.8.7-.5.6-.7.6c-.6.6-1 .9-1.3 1a4 4 0 01-1.8.5 4 4 0 01-2.4-.6 13 13 0 01-1.9-1.7l-2.4-2.4-.6-.6-1.4-1.3L6.1 12l-.5-.5V8.9l.6.5L7.9 11l1.4 1.4 1.3 1.2 1.3 1.3a195 195 0 012.6 2.4c.4.3 1 .5 1.6.4.5 0 1-.1 1.4-.4L19 16l1-1 1-1a214.7 214.7 0 012.2-2l1-1 2-2 .2-.2v2.8l-.3.3z'/%3e%3c/svg%3e");
  border-color: #fff;
  outline-color: #fff;
}

.btn-mail.btn-inverse {
  background-color: #000;
}

.btn-mail:hover, .btn-mail:focus {
  background-color: #ff7900;
  border-color: #ff7900;
  outline-color: #ff7900;
}

.btn-mail:active, .btn-mail.active {
  background-color: #000;
  border-color: #000;
  outline-color: #000;
}

.btn-snapchat, .btn-snapchat.btn-inverse:active, .btn-snapchat.btn-inverse.active {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 28'%3e%3cpath fill='%23000' d='M13 2c3 0 5 2 7 4v6h2l1 1-3 2v1l4 4h1l1 1-4 1-1 2h-2-1c-1 0-2 2-5 2s-4-2-5-2H5l-1-2-4-1 1-1h1l4-4v-1l-3-2 1-1h2V9 6c2-3 4-4 7-4z'/%3e%3c/svg%3e");
}

.btn-snapchat.btn-inverse:active, .btn-snapchat.btn-inverse.active {
  border-color: #fff;
}

.btn-snapchat:hover, .btn-snapchat:focus, .btn-snapchat:active, .btn-snapchat.active, .btn-snapchat.btn-inverse {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 28'%3e%3cpath fill='%23fff' d='M13 2c3 0 5 2 7 4v6h2l1 1-3 2v1l4 4h1l1 1-4 1-1 2h-2-1c-1 0-2 2-5 2s-4-2-5-2H5l-1-2-4-1 1-1h1l4-4v-1l-3-2 1-1h2V9 6c2-3 4-4 7-4z'/%3e%3c/svg%3e");
  border-color: #fff;
  outline-color: #fff;
}

.btn-snapchat.btn-inverse {
  background-color: #000;
}

.btn-snapchat:hover, .btn-snapchat:focus {
  background-color: #fffc00;
  border-color: #fffc00;
  outline-color: #fffc00;
}

.btn-snapchat:active, .btn-snapchat.active {
  background-color: #000;
  border-color: #000;
  outline-color: #000;
}

.btn-pinterest {
  background-size: 1.375rem;
}

.btn-pinterest, .btn-pinterest.btn-inverse:active, .btn-pinterest.btn-inverse.active {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath fill='%23000' d='M16 2a14 14 0 00-5 27v-4l2-7-1-2c0-2 1-3 3-3l1 2-1 4c0 2 1 3 2 3 3 0 5-3 5-7 0-3-3-5-6-5-4 0-6 3-6 6l1 3a302 302 0 01-1 2c-2-1-3-3-3-5 0-5 3-9 9-9 5 0 9 4 9 8 0 5-3 9-7 9l-4-2v4l-2 3a14 14 0 0018-13c0-8-6-14-14-14z'/%3e%3c/svg%3e");
}

.btn-pinterest.btn-inverse:active, .btn-pinterest.btn-inverse.active {
  border-color: #fff;
}

.btn-pinterest:hover, .btn-pinterest:focus, .btn-pinterest:active, .btn-pinterest.active, .btn-pinterest.btn-inverse {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath fill='%23fff' d='M16 2a14 14 0 00-5 27v-4l2-7-1-2c0-2 1-3 3-3l1 2-1 4c0 2 1 3 2 3 3 0 5-3 5-7 0-3-3-5-6-5-4 0-6 3-6 6l1 3a302 302 0 01-1 2c-2-1-3-3-3-5 0-5 3-9 9-9 5 0 9 4 9 8 0 5-3 9-7 9l-4-2v4l-2 3a14 14 0 0018-13c0-8-6-14-14-14z'/%3e%3c/svg%3e");
  border-color: #fff;
  outline-color: #fff;
}

.btn-pinterest.btn-inverse {
  background-color: #000;
}

.btn-pinterest:hover, .btn-pinterest:focus {
  background-color: red;
  border-color: red;
  outline-color: red;
}

.btn-pinterest:active, .btn-pinterest.active {
  background-color: #000;
  border-color: #000;
  outline-color: #000;
}

.btn-lg, .btn-group-lg > .btn {
  padding: calc(0.8125rem - 1px) 1.25rem calc(0.8125rem + 1px);
  font-size: 1.125rem;
  line-height: 1.111111;
  border-radius: 0;
}

.btn-lg.btn-icon, .btn-group-lg > .btn-icon.btn {
  min-width: 3.125rem;
  padding: 0.6875rem;
}

.btn-lg [class*="icon-"]::before, .btn-group-lg > .btn [class*="icon-"]::before {
  font-size: 1.5rem;
}

.btn-lg:not(.btn-icon) [class*="icon-"], .btn-group-lg > .btn:not(.btn-icon) [class*="icon-"] {
  margin-top: -0.125rem;
  margin-bottom: -0.125rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: calc(0.3125rem - 1px) 0.625rem calc(0.3125rem + 1px);
  font-size: 0.875rem;
  line-height: 1.142857;
  border-radius: 0;
}

.btn-sm.btn-icon, .btn-group-sm > .btn-icon.btn {
  min-width: 1.875rem;
  padding: 0.375rem;
}

.btn-sm [class*="icon-"]::before, .btn-group-sm > .btn [class*="icon-"]::before {
  font-size: 0.875rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse {
  will-change: height;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  padding: 0.5rem 0.625rem;
  color: #000;
  white-space: nowrap;
  background-color: #fff;
  border-color: #ccc;
}

.bg-dark .dropdown-toggle:not(.dropdown-toggle-split),
.navbar-dark .dropdown-toggle:not(.dropdown-toggle-split) {
  color: #fff;
  background-color: #000;
  border-color: #666;
}

.bg-dark .dropdown-toggle:not(.dropdown-toggle-split):hover,
.navbar-dark .dropdown-toggle:not(.dropdown-toggle-split):hover {
  border-color: #fff;
}

.dropdown-toggle.btn-lg, .btn-group-lg > .dropdown-toggle.btn {
  padding: 0.8125rem 1.25rem;
}

.dropdown-toggle.btn-sm, .btn-group-sm > .dropdown-toggle.btn {
  padding: 0.3125rem 0.625rem;
}

.btn-group .dropdown-toggle:not(.btn-inverse) {
  border-color: #000;
}

.dropdown-toggle::after {
  display: inline-flex;
  align-self: center;
  margin-left: 0.625rem;
  content: "";
  border-top: 0.4375rem solid;
  border-right: 0.4375rem solid transparent;
  border-bottom: 0;
  border-left: 0.4375rem solid transparent;
  transform: translateY(0.0625rem);
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  /* rtl:ignore */
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0;
  margin: -2px 0 0;
  font-size: 1rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.125rem solid #ccc;
}

.bg-dark .dropdown-menu {
  background-color: #000;
  border-color: #666;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 480px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1024px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1280px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1440px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -2px;
}

.dropup .dropdown-toggle::after {
  display: inline-flex;
  align-self: center;
  margin-left: 0.625rem;
  content: "";
  border-top: 0;
  border-right: 0.4375rem solid transparent;
  border-bottom: 0.4375rem solid;
  border-left: 0.4375rem solid transparent;
  transform: none;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: -2px;
}

.dropright .dropdown-toggle::after {
  display: inline-flex;
  align-self: center;
  margin-left: 0.625rem;
  content: "";
  border-top: 0.4375rem solid transparent;
  border-right: 0;
  border-bottom: 0.4375rem solid transparent;
  border-left: 0.4375rem solid;
  transform: none;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: -2px;
}

.dropleft .dropdown-toggle::after {
  display: inline-flex;
  align-self: center;
  margin-left: 0.625rem;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-flex;
  align-self: center;
  margin-right: 0.625rem;
  content: "";
  border-top: 0.4375rem solid transparent;
  border-right: 0.4375rem solid;
  border-bottom: 0.4375rem solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.625rem 0;
  overflow: hidden;
  border-top: 1px solid #eee;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.625rem 0.625rem;
  clear: both;
  font-weight: 700;
  color: #000;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #fff;
  text-decoration: none;
  background-color: #000;
}

.bg-dark .dropdown-item:hover, .bg-dark .dropdown-item:focus {
  color: #000;
  background-color: #fff;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #000;
}

.bg-dark .dropdown-item.active, .bg-dark .dropdown-item:active {
  color: #000;
  background-color: #fff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #ccc;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #000;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.625rem 0.625rem;
  color: #000;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group:not(.btn-group-toggle) > .btn:not(:first-child):not(.btn-icon),
.btn-group:not(.btn-group-toggle) > .btn-group:not(:first-child) {
  margin-left: -0.125rem;
}

.dropdown-toggle-split {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-color: #000;
}

:not(.dropleft) > .dropdown-toggle-split::after {
  transform: none;
}

:not(.dropleft) > .dropdown-toggle-split:not(:hover):not(:focus):not(:active) {
  border-left-color: transparent;
}

:not(.dropleft) > .dropdown-toggle-split:not(:hover):not(:focus):not(:active).btn-inverse::before {
  background-color: #000;
}

:not(.dropleft) > .dropdown-toggle-split:not(:focus):not(:active)::before {
  position: absolute;
  top: -0.125rem;
  bottom: -0.125rem;
  left: -0.125rem;
  width: 0.125rem;
  color: #000;
  content: "";
  background: linear-gradient(currentcolor 0.125rem, transparent 0.125rem, transparent 0.3125rem, currentcolor 0.3125rem, currentcolor calc(100% - 0.3125rem), transparent calc(100% - 0.3125rem), transparent calc(100% - 0.125rem), currentcolor calc(100% - 0.125rem));
}

:not(.dropleft) > .dropdown-toggle-split:not(:focus):not(:active).btn-inverse::before {
  color: #fff;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn:active + .dropdown-toggle-split {
  border-color: #ff7900;
}

.btn-group.show:not(.dropleft) > .dropdown-toggle-split:not(:focus):not(:active)::before {
  color: #ff7900;
  background-color: #ff7900;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.3125rem;
  padding-left: 0.3125rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.btn-group.show .btn {
  border-color: #ff7900;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -0.125rem;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
  border-color: #ccc;
}

.btn-group-toggle > .btn [type="radio"],
.btn-group-toggle > .btn [type="checkbox"],
.btn-group-toggle > .btn-group > .btn [type="radio"],
.btn-group-toggle > .btn-group > .btn [type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-group-toggle > .btn:hover, .btn-group-toggle > .btn:focus, .btn-group-toggle > .btn.focus,
.btn-group-toggle > .btn-group > .btn:hover,
.btn-group-toggle > .btn-group > .btn:focus,
.btn-group-toggle > .btn-group > .btn.focus {
  color: #f16e00;
  background-color: #fff;
  border-color: #ccc;
}

.btn-group-toggle > .btn:active,
.btn-group-toggle > .btn-group > .btn:active {
  color: #000;
  background-color: #ff7900;
}

.btn-group-toggle > .btn:not(:disabled):not(.disabled).active,
.btn-group-toggle > .btn-group > .btn:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #000;
  border-color: #000;
  outline-color: #000;
}

.bg-dark .btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
  border-color: #666;
}

.bg-dark .btn-group-toggle > .btn:hover, .bg-dark .btn-group-toggle > .btn:focus, .bg-dark .btn-group-toggle > .btn.focus, .btn-group-toggle > .btn-group > .btn:hover, .btn-group-toggle > .btn-group > .btn:focus, .btn-group-toggle > .btn-group > .btn.focus {
  color: #ff7900;
  background-color: #000;
  border-color: #666;
}

.bg-dark .btn-group-toggle > .btn:not(:disabled):not(.disabled).active, .btn-group-toggle > .btn-group > .btn:not(:disabled):not(.disabled).active {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  outline-color: #fff;
}

.btn-group-toggle > .btn:not(:disabled):not(.disabled).active.focus-visible,
.btn-group-toggle > .btn-group > .btn:not(:disabled):not(.disabled).active.focus-visible {
  color: #000;
  background-color: #ff7900;
  border-color: #ff7900;
  outline-color: #ff7900;
}

.btn-group-toggle > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-toggle > .btn-group:not(:last-child) > .btn {
  border-right: 0;
}

.btn-group-toggle > .btn:not(:first-child),
.btn-group-toggle > .btn-group:not(:first-child) > .btn {
  border-left: 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -0.125rem;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -0.125rem;
}

.input-group-prepend {
  margin-right: -0.125rem;
}

.input-group-append {
  margin-left: -0.125rem;
}

.form-control:focus + .input-group-append,
.custom-select:focus + .input-group-append {
  position: relative;
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.625rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  color: #000;
  text-align: center;
  white-space: nowrap;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: 3.125rem;
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.8125rem 1.25rem;
  font-size: 1.125rem;
  line-height: 1.111111;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: 1.875rem;
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.3125rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.142857;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.625rem;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.125rem;
  padding-left: 1.875rem;
  margin-bottom: 0.625rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1.25rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.25rem;
  height: 1.1875rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #000;
  border-color: #ff7900;
  background-color: #ff7900;
}

.custom-control-input:checked ~ .custom-control-label::before::before {
  background-color: #ff7900;
}

.custom-control-input:focus ~ .custom-control-label::before {
  outline: 0.125rem solid #000;
}

.js-focus-visible .custom-control-input:focus:not([data-focus-visible-added]) ~ .custom-control-label::before {
  outline: 0;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #000;
  background-color: #ff7900;
  border-color: #ff7900;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #ccc;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  border-color: #ccc;
  background-color: #eee;
}

.custom-control-input[disabled]:checked ~ .custom-control-label::before, .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #ccc;
}

.custom-control-label {
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: -0.0625rem;
  left: 0;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 0.125rem solid #ccc;
}

.custom-control-label::after {
  position: absolute;
  top: -0.0625rem;
  left: 0;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  content: "";
  background: 50% / 0.9375rem no-repeat;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 12'%3e%3cpath fill='%23000' d='M13 0L5 8 2 5 0 7l5 5L15 2z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border: 0.125rem solid #ff7900;
}

.custom-checkbox .custom-control-input:checked:focus ~ .custom-control-label::before {
  outline-color: #000;
}

.custom-checkbox .js-focus-visible .custom-control-input:checked:focus:not([data-focus-visible-added]) ~ .custom-control-label::before {
  outline: 0;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #000;
  background-color: #000;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 3'%3e%3cpath fill='%23fff' d='M0 0h10v3H0z'/%3e%3c/svg%3e");
  background-size: 0.625rem 0.1875rem;
  transform: translateY(0.03125rem);
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  border-color: #ccc;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 12'%3e%3cpath fill='%23fff' d='M13 0L5 8 2 5 0 7l5 5L15 2z'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #000;
  border-color: #000;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  border: 6px solid #ff7900;
  border-radius: 50%;
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::after {
  border-color: #ccc;
}

.custom-switch {
  padding-left: 4.375rem;
}

.custom-switch .custom-control-input:focus ~ .custom-control-label::after {
  outline: 0.125rem solid #fff;
}

.js-focus-visible .custom-switch .custom-control-input:focus:not([data-focus-visible-added]) ~ .custom-control-label::after {
  outline: 0;
}

.custom-switch .custom-control-label {
  padding-top: 0.3125rem;
}

.custom-switch .custom-control-label::before {
  left: 0;
  width: 3.75rem;
  height: 1.875rem;
  pointer-events: all;
  background: #000 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath fill='%23fff' stroke='%23fff' stroke-width='5' stroke-linecap='round' d='M15 17.121l-8.132 8.132-2.121-2.12L12.879 15 4.747 6.868l2.12-2.121L15 12.879l8.132-8.132 2.12 2.121L17.122 15l8.132 8.132-2.121 2.12L15 17.123z'/%3e%3c/svg%3e") no-repeat right 0.5rem top 50%/0.75rem;
  border-color: #000;
}

.custom-switch .custom-control-label::after {
  top: 0.0625rem;
  left: 0.125rem;
  width: 1.625rem;
  height: 1.625rem;
  background-color: #fff;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch.right {
  padding-right: 4.375rem;
  padding-left: 0;
}

.custom-switch.right .custom-control-label::before {
  right: 0;
  left: auto;
}

.custom-switch.right .custom-control-label::after {
  right: 2rem;
  left: auto;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background: #ff7900 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 12'%3e%3cpath fill='%23000' d='M13 0L5 8 2 5 0 7l5 5L15 2z'/%3e%3c/svg%3e") no-repeat left 0.375rem top 50% / 0.9375rem;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(1.875rem);
}

.custom-switch .custom-control-input:disabled ~ .custom-control-label::before,
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #ccc;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 12'%3e%3cpath fill='%23fff' d='M13 0L5 8 2 5 0 7l5 5L15 2z'/%3e%3c/svg%3e");
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 2.5rem;
  padding: 0.4375rem 1.625rem 0.4375rem 0.625rem;
  font-family: HelvNeueOrange/*rtl:insert:Arabic*/, Helvetica Neue, Helvetica, Arial, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  color: #000;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 7'%3e%3cpath d='M7 7L0 0h14L7 7z'/%3e%3c/svg%3e") right 0.625rem top calc(50% + 1px) / 0.875rem 1rem no-repeat;
  border: 0.125rem solid #ccc;
  border-radius: 0;
  appearance: none;
}

.custom-select:focus {
  border-color: #000;
  outline: 0;
}

.custom-select:focus::-ms-value {
  color: #000;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.625rem;
  background-image: none;
}

.custom-select:disabled {
  color: #666;
  background-color: #eee;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 7'%3e%3cpath fill='%23666' d='M7 7L0 0h14L7 7z'/%3e%3c/svg%3e");
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.custom-select-sm {
  height: 1.875rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  padding-left: 0.625rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: 3.125rem;
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
  padding-left: 1.25rem;
  font-size: 1.125rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 2.5rem;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 2.5rem;
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #000;
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #eee;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 2.5rem;
  padding: 0.5rem 0.625rem;
  overflow: hidden;
  font-family: HelvNeueOrange/*rtl:insert:Arabic*/, Helvetica Neue, Helvetica, Arial, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 700;
  line-height: 1.25;
  color: #000;
  background-color: #fff;
  border: 0.125rem solid #ccc;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.5rem 0.625rem;
  line-height: 1.25;
  color: #000;
  content: "Browse";
  background-color: #eee;
  border-left: inherit;
}

.custom-range {
  width: 100%;
  height: 1.25rem;
  padding: 0;
  background-color: transparent;
  outline-offset: 0.5rem;
  appearance: none;
  transition: outline-offset 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range {
    transition: none;
  }
}

.custom-range:focus {
  outline: 0.125rem solid #000;
  outline-offset: 0.125rem;
}

.custom-range:focus::-webkit-slider-thumb {
  border-color: #ff7900;
}

.custom-range:focus::-moz-range-thumb {
  border-color: #ff7900;
}

.custom-range:focus::-ms-thumb {
  border-color: #ff7900;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  box-sizing: content-box;
  width: 1rem;
  height: 1rem;
  margin-top: calc(-0.5rem + 1px);
  cursor: grab;
  background-color: #fff;
  border: 0.125rem solid #000;
  border-radius: 50%;
  appearance: none;
}

.custom-range::-webkit-slider-thumb:active {
  cursor: grabbing;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.375rem;
  color: transparent;
  cursor: pointer;
  background-color: #eee;
  border-color: transparent;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  cursor: grab;
  background-color: #fff;
  border: 0.125rem solid #000;
  border-radius: 50%;
  appearance: none;
}

.custom-range::-moz-range-thumb:active {
  cursor: grabbing;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.375rem;
  color: transparent;
  cursor: pointer;
  background-color: #eee;
  border-color: transparent;
}

.custom-range::-moz-range-progress {
  height: 0.375rem;
  background-color: #ff7900;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.125rem;
  margin-left: 0.125rem;
  cursor: grab;
  background-color: #fff;
  border: 0.125rem solid #000;
  border-radius: 50%;
  appearance: none;
}

.custom-range::-ms-thumb:active {
  cursor: grabbing;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.375rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #ff7900;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #eee;
}

.custom-range:disabled::-webkit-slider-thumb {
  cursor: default;
  border-color: #ccc;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  cursor: default;
  border-color: #ccc;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-progress {
  background-color: #ccc;
}

.custom-range:disabled::-ms-thumb {
  cursor: default;
  border-color: #ccc;
}

.custom-range:disabled::-ms-fill-lower {
  background-color: #ccc;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: flex;
  padding: 0.625rem 1.25rem;
  font-weight: 700;
  text-decoration: none;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #ccc;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 0.125rem solid #000;
}

@media (min-width: 1024px) {
  .nav-tabs .nav-item + .nav-item {
    margin-left: .5rem;
  }
}

.nav-tabs .nav-link {
  padding: 0.875rem 1.875rem;
  margin-bottom: -0.125rem;
  background: none;
  border: 0.125rem solid transparent;
  border-bottom-width: 0;
  outline-offset: 0.625rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  color: #fff;
  background-color: #000;
  isolation: isolate;
  border-color: #000;
}

.nav-tabs .nav-link.disabled {
  color: #ccc;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link:not(.disabled):focus {
  outline: 0.125rem solid #000;
  outline-offset: -0.125rem;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #000;
  background-color: #fff;
  border-color: #000;
}

.nav-tabs .dropdown-menu {
  margin-top: -0.125rem;
}

.nav-tabs-light {
  padding-left: 0;
  border-bottom: 0.0625rem solid #999;
}

.nav-tabs-light .nav-item {
  margin-bottom: -0.0625rem;
}

.nav-tabs-light .nav-link {
  border-width: 0 0 0.25rem;
}

.nav-tabs-light .nav-link:hover, .nav-tabs-light .nav-link:focus {
  color: #f16e00;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs-light .nav-link.active {
  border-color: transparent transparent #f16e00;
}

.nav-tabs-light .nav-link:not(.disabled):focus {
  outline-color: currentcolor;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
}

.nav-pills .nav-link:focus {
  outline-offset: -0.125rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #000 !important;
  background-color: #ff7900;
}

.nav-pills .nav-link.active:focus,
.nav-pills .show > .nav-link:focus {
  outline: 0.125rem solid #ff7900;
}

.nav-pills .nav-item + .nav-item {
  margin-left: 0.3125rem;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content {
  padding: 1.125rem;
  border: 0.125rem solid;
  border-top: 0;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.tab-pane > :last-child {
  margin-bottom: 0;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  font-weight: 700;
  transition: padding 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar {
    transition: none;
  }
}

@media (min-width: 768px) {
  .navbar {
    padding: 0.875rem 0;
  }
}

.navbar .container,
.navbar .container-fluid, .navbar .container-xs, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl, .navbar .container-xxl {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-right: 0;
  padding-left: 0;
}

@media (min-width: 768px) {
  .navbar.p-0 .nav-link.active::before, .navbar.py-0 .nav-link.active::before, .navbar.pb-0 .nav-link.active::before {
    bottom: -0.125rem;
  }
}

.navbar .nav-link.active.pl-0::before {
  left: 0;
}

.navbar-brand {
  position: relative;
  display: inline-flex;
  align-items: baseline;
  align-self: center;
  padding: 0;
  margin: 0.3125rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: .75;
  text-decoration: none;
  letter-spacing: inherit;
  white-space: nowrap;
  outline-offset: 1.25rem;
  transition: outline-offset 0.2s ease-in-out, margin 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-brand {
    transition: none;
  }
}

@media (min-width: 480px) {
  .navbar-brand {
    align-self: flex-end;
    margin: 0.3125rem 0.3125rem 0.5625rem 0;
  }
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-brand + .navbar-brand {
  margin: 0.625rem 0 0.625rem 1.25rem;
}

.navbar-brand h1,
.navbar-brand [class*="display-"],
.navbar-brand .h1, .navbar-brand .h2, .navbar-brand .h3, .navbar-brand .h4, .navbar-brand .h5, .navbar-brand .h6 {
  line-height: 1.5625rem;
}

.navbar-brand:focus {
  outline: 0.125rem solid #fff;
  outline-offset: 0;
}

.navbar-brand::before {
  position: absolute;
  top: 0.9375rem;
  left: 0.9375rem;
  width: 3.125rem;
  height: 3.125rem;
  content: "";
  transform: translate3d(-50%, -50%, 0);
}

.navbar-brand img {
  width: 1.875rem;
  height: 1.875rem;
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-brand img {
    transition: none;
  }
}

.navbar-brand img + * {
  margin: 0 1.25rem 0 0.9375rem;
}

@media (min-width: 480px) {
  .navbar-brand img + * {
    margin-left: 1.5625rem;
  }
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-top: auto;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  position: relative;
  padding: 0.625rem 0.3125rem;
  outline-offset: 1.25rem;
  transition: outline-offset 0.2s ease-in-out, padding 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-nav .nav-link {
    transition: none;
  }
}

.navbar-nav .nav-link[class*="svg-"]::after {
  width: 1.5625rem;
  height: 1.5625rem;
}

.navbar-nav .nav-link:focus {
  outline-offset: -0.125rem;
}

.navbar-nav .nav-link.active::before {
  position: absolute;
  right: 0.3125rem;
  bottom: 0;
  left: 0.3125rem;
  height: 0.25rem;
  content: "";
  background-color: currentcolor;
  transition: bottom 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-nav .nav-link.active::before {
    transition: none;
  }
}

.navbar-nav .nav-icon {
  padding: 0.625rem 0.9375rem;
  border-top: 0.125rem solid transparent;
}

.navbar-nav .btn-link,
.navbar-nav.flex-row .nav-item {
  border: 0;
}

.navbar-nav:last-child .nav-item:last-child .nav-link {
  padding-right: 0;
}

.navbar-nav:last-child .nav-item:last-child .nav-link::before {
  right: 0;
}

.navbar-nav .badge {
  position: absolute;
  top: 0.3125rem;
  right: 0;
}

@media (min-width: 480px) {
  .navbar-nav .nav-link {
    padding: 0.875rem 0.625rem;
  }
  .navbar-nav .nav-link[class*="svg-"]::after {
    width: 1.875rem;
    height: 1.875rem;
  }
  .navbar-nav .nav-link.active::before {
    right: 0.625rem;
    left: 0.625rem;
  }
  .navbar-nav .nav-icon {
    padding: 0.125rem 1.25rem 1rem;
  }
  .navbar-nav .badge {
    top: 0.625rem;
    right: 0.625rem;
  }
}

.navbar-nav .dropdown-menu {
  position: static;
  top: 3rem;
  float: none;
}

.navbar-nav .nav-item {
  margin-left: 0;
  font-size: 1.125rem;
  border-top: 1px solid #333;
}

.navbar-nav .dropdown {
  padding: 0;
}

.navbar-nav .dropdown-toggle {
  padding: 0.875rem 0 0.875rem 0.625rem;
  margin-right: 0.625rem;
}

.navbar-nav .dropdown-toggle::after {
  margin-left: 0.3125rem;
}

.navbar-nav .dropdown-toggle[aria-expanded="true"]::after {
  transform: rotateZ(180deg);
}

.navbar-nav .dropdown-item {
  padding: 0.875rem 0 0.875rem 0.625rem;
  color: #fff;
  white-space: normal;
  background-color: #000;
  border-top: 1px solid #333;
}

.navbar-nav .dropdown-item:focus {
  color: #ff7900;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-self: flex-end;
}

.navbar-collapse .navbar-nav {
  align-items: stretch;
}

.navbar-toggler {
  padding: 0.5rem 0.5rem;
  margin: 0.15625rem 0 0.15625rem 0.3125rem;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: 0.125rem solid transparent;
}

@media (min-width: 480px) {
  .navbar-toggler {
    margin: 0.3125rem 0 0;
  }
}

.navbar-toggler:hover, .navbar-toggler:focus {
  color: #fff;
  text-decoration: none;
}

.navbar-toggler:hover .navbar-toggler-icon,
.navbar-toggler:focus .navbar-toggler-icon,
.navbar-toggler:active .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23ff7900' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-toggler:focus {
  outline: 0;
}

.navbar .navbar-toggler[aria-expanded="true"]:hover .navbar-toggler-icon,
.navbar .navbar-toggler[aria-expanded="true"]:focus .navbar-toggler-icon,
.navbar .navbar-toggler[aria-expanded="true"]:active .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath fill='%23ff7900' d='M15 17.121l-8.132 8.132-2.121-2.12L12.879 15 4.747 6.868l2.12-2.121L15 12.879l8.132-8.132 2.12 2.121L17.122 15l8.132 8.132-2.121 2.12L15 17.123z'/%3e%3c/svg%3e");
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5625rem;
  height: 1.5625rem;
  vertical-align: middle;
  content: "";
  background: 0.5 100% no-repeat;
}

@media (min-width: 480px) {
  .navbar-toggler-icon {
    width: 1.875rem;
    height: 1.875rem;
  }
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

.navbar-expand-sm {
  padding: 1.5625rem 0 0.4375rem;
}

.navbar-expand-sm .nav-link.active::before {
  right: 0.625rem;
  bottom: 0;
  left: 0.625rem;
}

.navbar-expand-sm .navbar-brand {
  margin: 0.125rem 1.25rem 1rem 0;
}

.navbar-expand-sm .navbar-brand img {
  width: 3.125rem;
  height: 3.125rem;
}

.navbar-expand-sm:not(.minimized) .navbar-brand::before {
  top: 0;
  left: 0;
  transform: none;
}

.navbar-expand-sm.minimized {
  padding-top: 0.875rem;
  padding-bottom: .1875rem;
}

.navbar-expand-sm.minimized .nav-link.active::before {
  bottom: 0;
}

.navbar-expand-sm.minimized .navbar-brand {
  margin-top: 0;
  margin-bottom: .8125rem;
}

.navbar-expand-sm.minimized .navbar-brand img {
  width: 1.875rem;
  height: 1.875rem;
}

.navbar-expand-sm.minimized .nav-link {
  padding: 0.625rem;
}

.navbar-expand-sm.minimized .nav-icon {
  padding: 0 1.25rem 0.5rem;
}

.navbar-expand-sm.minimized .navbar-toggler {
  padding: 0.3125rem;
  margin-top: -0.625rem;
  border-width: 0.0625rem;
}

@media (min-width: 480px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .nav-link.active::before {
    bottom: -.4375rem;
  }
  .navbar-expand-sm.minimized .nav-link.active::before {
    bottom: -.1875rem;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .dropdown-item {
    color: #000;
    background-color: #fff;
    border: 0;
  }
  .navbar-expand-sm .navbar-nav .dropdown-item.active, .navbar-expand-sm .navbar-nav .dropdown-item:hover {
    color: #fff;
    background-color: #000;
  }
  .navbar-expand-sm .navbar-nav .nav-item {
    font-size: 1rem;
    border: 0;
  }
  .navbar-expand-sm .navbar-nav .nav-item.dropdown {
    align-self: center;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-xs, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl {
    flex-wrap: nowrap;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 480px) {
  .navbar-expand-md {
    padding: 1.5625rem 0 0.4375rem;
  }
  .navbar-expand-md .nav-link.active::before {
    right: 0.625rem;
    bottom: 0;
    left: 0.625rem;
  }
  .navbar-expand-md .navbar-brand {
    margin: 0.125rem 1.25rem 1rem 0;
  }
  .navbar-expand-md .navbar-brand img {
    width: 3.125rem;
    height: 3.125rem;
  }
  .navbar-expand-md:not(.minimized) .navbar-brand::before {
    top: 0;
    left: 0;
    transform: none;
  }
  .navbar-expand-md.minimized {
    padding-top: 0.875rem;
    padding-bottom: .1875rem;
  }
  .navbar-expand-md.minimized .nav-link.active::before {
    bottom: 0;
  }
  .navbar-expand-md.minimized .navbar-brand {
    margin-top: 0;
    margin-bottom: .8125rem;
  }
  .navbar-expand-md.minimized .navbar-brand img {
    width: 1.875rem;
    height: 1.875rem;
  }
  .navbar-expand-md.minimized .nav-link {
    padding: 0.625rem;
  }
  .navbar-expand-md.minimized .nav-icon {
    padding: 0 1.25rem 0.5rem;
  }
  .navbar-expand-md.minimized .navbar-toggler {
    padding: 0.3125rem;
    margin-top: -0.625rem;
    border-width: 0.0625rem;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .nav-link.active::before {
    bottom: -.4375rem;
  }
  .navbar-expand-md.minimized .nav-link.active::before {
    bottom: -.1875rem;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .dropdown-item {
    color: #000;
    background-color: #fff;
    border: 0;
  }
  .navbar-expand-md .navbar-nav .dropdown-item.active, .navbar-expand-md .navbar-nav .dropdown-item:hover {
    color: #fff;
    background-color: #000;
  }
  .navbar-expand-md .navbar-nav .nav-item {
    font-size: 1rem;
    border: 0;
  }
  .navbar-expand-md .navbar-nav .nav-item.dropdown {
    align-self: center;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-xs, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
    flex-wrap: nowrap;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-lg {
    padding: 1.5625rem 0 0.4375rem;
  }
  .navbar-expand-lg .nav-link.active::before {
    right: 0.625rem;
    bottom: 0;
    left: 0.625rem;
  }
  .navbar-expand-lg .navbar-brand {
    margin: 0.125rem 1.25rem 1rem 0;
  }
  .navbar-expand-lg .navbar-brand img {
    width: 3.125rem;
    height: 3.125rem;
  }
  .navbar-expand-lg:not(.minimized) .navbar-brand::before {
    top: 0;
    left: 0;
    transform: none;
  }
  .navbar-expand-lg.minimized {
    padding-top: 0.875rem;
    padding-bottom: .1875rem;
  }
  .navbar-expand-lg.minimized .nav-link.active::before {
    bottom: 0;
  }
  .navbar-expand-lg.minimized .navbar-brand {
    margin-top: 0;
    margin-bottom: .8125rem;
  }
  .navbar-expand-lg.minimized .navbar-brand img {
    width: 1.875rem;
    height: 1.875rem;
  }
  .navbar-expand-lg.minimized .nav-link {
    padding: 0.625rem;
  }
  .navbar-expand-lg.minimized .nav-icon {
    padding: 0 1.25rem 0.5rem;
  }
  .navbar-expand-lg.minimized .navbar-toggler {
    padding: 0.3125rem;
    margin-top: -0.625rem;
    border-width: 0.0625rem;
  }
}

@media (min-width: 1024px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .nav-link.active::before {
    bottom: -.4375rem;
  }
  .navbar-expand-lg.minimized .nav-link.active::before {
    bottom: -.1875rem;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .dropdown-item {
    color: #000;
    background-color: #fff;
    border: 0;
  }
  .navbar-expand-lg .navbar-nav .dropdown-item.active, .navbar-expand-lg .navbar-nav .dropdown-item:hover {
    color: #fff;
    background-color: #000;
  }
  .navbar-expand-lg .navbar-nav .nav-item {
    font-size: 1rem;
    border: 0;
  }
  .navbar-expand-lg .navbar-nav .nav-item.dropdown {
    align-self: center;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-xs, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
    flex-wrap: nowrap;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1024px) {
  .navbar-expand-xl {
    padding: 1.5625rem 0 0.4375rem;
  }
  .navbar-expand-xl .nav-link.active::before {
    right: 0.625rem;
    bottom: 0;
    left: 0.625rem;
  }
  .navbar-expand-xl .navbar-brand {
    margin: 0.125rem 1.25rem 1rem 0;
  }
  .navbar-expand-xl .navbar-brand img {
    width: 3.125rem;
    height: 3.125rem;
  }
  .navbar-expand-xl:not(.minimized) .navbar-brand::before {
    top: 0;
    left: 0;
    transform: none;
  }
  .navbar-expand-xl.minimized {
    padding-top: 0.875rem;
    padding-bottom: .1875rem;
  }
  .navbar-expand-xl.minimized .nav-link.active::before {
    bottom: 0;
  }
  .navbar-expand-xl.minimized .navbar-brand {
    margin-top: 0;
    margin-bottom: .8125rem;
  }
  .navbar-expand-xl.minimized .navbar-brand img {
    width: 1.875rem;
    height: 1.875rem;
  }
  .navbar-expand-xl.minimized .nav-link {
    padding: 0.625rem;
  }
  .navbar-expand-xl.minimized .nav-icon {
    padding: 0 1.25rem 0.5rem;
  }
  .navbar-expand-xl.minimized .navbar-toggler {
    padding: 0.3125rem;
    margin-top: -0.625rem;
    border-width: 0.0625rem;
  }
}

@media (min-width: 1280px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .nav-link.active::before {
    bottom: -.4375rem;
  }
  .navbar-expand-xl.minimized .nav-link.active::before {
    bottom: -.1875rem;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .dropdown-item {
    color: #000;
    background-color: #fff;
    border: 0;
  }
  .navbar-expand-xl .navbar-nav .dropdown-item.active, .navbar-expand-xl .navbar-nav .dropdown-item:hover {
    color: #fff;
    background-color: #000;
  }
  .navbar-expand-xl .navbar-nav .nav-item {
    font-size: 1rem;
    border: 0;
  }
  .navbar-expand-xl .navbar-nav .nav-item.dropdown {
    align-self: center;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-xs, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
    flex-wrap: nowrap;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1280px) {
  .navbar-expand-xxl {
    padding: 1.5625rem 0 0.4375rem;
  }
  .navbar-expand-xxl .nav-link.active::before {
    right: 0.625rem;
    bottom: 0;
    left: 0.625rem;
  }
  .navbar-expand-xxl .navbar-brand {
    margin: 0.125rem 1.25rem 1rem 0;
  }
  .navbar-expand-xxl .navbar-brand img {
    width: 3.125rem;
    height: 3.125rem;
  }
  .navbar-expand-xxl:not(.minimized) .navbar-brand::before {
    top: 0;
    left: 0;
    transform: none;
  }
  .navbar-expand-xxl.minimized {
    padding-top: 0.875rem;
    padding-bottom: .1875rem;
  }
  .navbar-expand-xxl.minimized .nav-link.active::before {
    bottom: 0;
  }
  .navbar-expand-xxl.minimized .navbar-brand {
    margin-top: 0;
    margin-bottom: .8125rem;
  }
  .navbar-expand-xxl.minimized .navbar-brand img {
    width: 1.875rem;
    height: 1.875rem;
  }
  .navbar-expand-xxl.minimized .nav-link {
    padding: 0.625rem;
  }
  .navbar-expand-xxl.minimized .nav-icon {
    padding: 0 1.25rem 0.5rem;
  }
  .navbar-expand-xxl.minimized .navbar-toggler {
    padding: 0.3125rem;
    margin-top: -0.625rem;
    border-width: 0.0625rem;
  }
}

@media (min-width: 1440px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .nav-link.active::before {
    bottom: -.4375rem;
  }
  .navbar-expand-xxl.minimized .nav-link.active::before {
    bottom: -.1875rem;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-item {
    color: #000;
    background-color: #fff;
    border: 0;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-item.active, .navbar-expand-xxl .navbar-nav .dropdown-item:hover {
    color: #fff;
    background-color: #000;
  }
  .navbar-expand-xxl .navbar-nav .nav-item {
    font-size: 1rem;
    border: 0;
  }
  .navbar-expand-xxl .navbar-nav .nav-item.dropdown {
    align-self: center;
  }
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-xs, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
    flex-wrap: nowrap;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

@media (min-width: 1440px) {
  .navbar-expand {
    padding: 1.5625rem 0 0.4375rem;
  }
  .navbar-expand .nav-link.active::before {
    right: 0.625rem;
    bottom: 0;
    left: 0.625rem;
  }
  .navbar-expand .navbar-brand {
    margin: 0.125rem 1.25rem 1rem 0;
  }
  .navbar-expand .navbar-brand img {
    width: 3.125rem;
    height: 3.125rem;
  }
  .navbar-expand:not(.minimized) .navbar-brand::before {
    top: 0;
    left: 0;
    transform: none;
  }
  .navbar-expand.minimized {
    padding-top: 0.875rem;
    padding-bottom: .1875rem;
  }
  .navbar-expand.minimized .nav-link.active::before {
    bottom: 0;
  }
  .navbar-expand.minimized .navbar-brand {
    margin-top: 0;
    margin-bottom: .8125rem;
  }
  .navbar-expand.minimized .navbar-brand img {
    width: 1.875rem;
    height: 1.875rem;
  }
  .navbar-expand.minimized .nav-link {
    padding: 0.625rem;
  }
  .navbar-expand.minimized .nav-icon {
    padding: 0 1.25rem 0.5rem;
  }
  .navbar-expand.minimized .navbar-toggler {
    padding: 0.3125rem;
    margin-top: -0.625rem;
    border-width: 0.0625rem;
  }
}

.navbar-expand .nav-link.active::before {
  bottom: -.4375rem;
}

.navbar-expand.minimized .nav-link.active::before {
  bottom: -.1875rem;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .dropdown-item {
  color: #000;
  background-color: #fff;
  border: 0;
}

.navbar-expand .navbar-nav .dropdown-item.active, .navbar-expand .navbar-nav .dropdown-item:hover {
  color: #fff;
  background-color: #000;
}

.navbar-expand .navbar-nav .nav-item {
  font-size: 1rem;
  border: 0;
}

.navbar-expand .navbar-nav .nav-item.dropdown {
  align-self: center;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-xs, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
  flex-wrap: nowrap;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: #000;
}

.navbar-light a.navbar-brand:hover, .navbar-light a.navbar-brand:focus {
  color: #f16e00;
}

.navbar-light .nav-item,
.navbar-light .dropdown-item {
  border-color: #ccc;
}

.navbar-light .nav-link {
  color: #000;
}

.navbar-light .nav-link:hover, .navbar-light .nav-link:focus {
  color: #f16e00;
}

.navbar-light .nav-link.disabled {
  color: #ccc;
}

.navbar-light .show > .nav-link,
.navbar-light .active > .nav-link,
.navbar-light .nav-link.show,
.navbar-light .nav-link.active {
  color: #f16e00;
}

.navbar-light .navbar-toggler {
  color: #000;
}

.navbar-light .navbar-toggler:hover, .navbar-light .navbar-toggler:focus {
  color: #f16e00;
}

.navbar-light .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath d='M15 17.121l-8.132 8.132-2.121-2.12L12.879 15 4.747 6.868l2.12-2.121L15 12.879l8.132-8.132 2.12 2.121L17.122 15l8.132 8.132-2.121 2.12L15 17.123z'/%3e%3c/svg%3e");
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23000' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #000;
}

.navbar-light .navbar-text a {
  color: #f16e00;
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #f16e00;
}

.navbar-dark {
  color: #fff;
  background-color: #000;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark a.navbar-brand:hover, .navbar-dark a.navbar-brand:focus {
  color: #ff7900;
}

.navbar-dark .nav-link {
  color: #fff;
}

.navbar-dark .nav-link:hover, .navbar-dark .nav-link:focus {
  color: #ff7900;
}

.navbar-dark .nav-link.disabled {
  color: #ddd;
}

.navbar-dark .show > .nav-link,
.navbar-dark .active > .nav-link,
.navbar-dark .nav-link.show,
.navbar-dark .nav-link.active {
  color: #ff7900;
}

.navbar-dark .navbar-toggler {
  color: #fff;
  background-color: #000;
}

.navbar-dark .navbar-toggler:hover, .navbar-dark .navbar-toggler:focus {
  color: #ff7900;
}

.navbar-dark .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath fill='%23fff' d='M15 17.121l-8.132 8.132-2.121-2.12L12.879 15 4.747 6.868l2.12-2.121L15 12.879l8.132-8.132 2.12 2.121L17.122 15l8.132 8.132-2.121 2.12L15 17.123z'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #fff;
}

.navbar-dark .navbar-text a {
  color: #ff7900;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #ff7900;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0.125rem solid #ccc;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card > .list-group + .card-footer {
  padding-top: 0.375rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-title:only-child {
  margin-bottom: 0;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  font-weight: 700;
  color: #fff;
  background-color: #000;
  border-bottom: 0.125rem solid #000;
}

.card-footer {
  padding: 0 1.25rem 1.5rem;
  font-weight: 700;
  color: #666;
  background-color: #fff;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-deck .card {
  margin-bottom: 1.875rem;
}

@media (min-width: 480px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -1.875rem;
    margin-left: -1.875rem;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 1.875rem;
    margin-bottom: 0;
    margin-left: 1.875rem;
  }
}

.card-group > .card {
  margin-bottom: 0.3125rem;
}

@media (min-width: 480px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
}

.card-columns .card {
  margin-bottom: 1.875rem;
}

@media (min-width: 480px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion .card {
  position: static;
  border-color: #ccc;
  border-width: 0 0 0.125rem;
}

.accordion .card:first-child {
  border-width: 0.125rem 0;
}

@media (min-width: 768px) {
  .accordion .card {
    border-width: 0 0 0.0625rem;
  }
  .accordion .card:first-child {
    border-width: 0.0625rem 0;
  }
}

.accordion .card-header {
  padding: 0;
  background: none;
  border: 0;
}

.accordion .card-header button,
.accordion .card-header a {
  display: flex;
  width: calc(100% + 1.75rem);
  padding: 0.875rem;
  margin: 0 -0.875rem;
  font-size: 1.5rem;
  line-height: 1.083333;
  text-decoration: none;
  letter-spacing: -0.03125rem;
  border: 0;
}

.accordion .card-header button::after,
.accordion .card-header a::after {
  display: block;
  align-self: center;
  width: 0.875rem;
  height: .4375rem;
  margin: 0 0.625rem 0 auto;
  content: "";
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 7'%3e%3cpath d='M7 7L0 0h14L7 7z'/%3e%3c/svg%3e") no-repeat 50%/0.875rem 1rem;
}

.accordion .card-header button:hover::after, .accordion .card-header button[aria-expanded="true"]::after,
.accordion .card-header a:hover::after,
.accordion .card-header a[aria-expanded="true"]::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 7'%3e%3cpath fill='%23f16e00' d='M7 7L0 0h14L7 7z'/%3e%3c/svg%3e");
}

.accordion .card-header button:focus,
.accordion .card-header a:focus {
  z-index: 2;
  outline-offset: 0;
}

.accordion .card-header .btn-sm, .accordion .card-header .btn-group-sm > .btn {
  width: calc(100% + 1.5rem);
  padding: 0.75rem;
  margin: 0 -0.75rem;
  font-size: 1.125rem;
  line-height: 1.111111;
  letter-spacing: -0.0125rem;
}

.accordion .card-header .btn-lg, .accordion .card-header .btn-group-lg > .btn {
  width: calc(100% + 2.5rem);
  padding: 1.25rem;
  margin: 0 -1.25rem;
  font-size: 1.875rem;
  line-height: 1.066667;
  letter-spacing: -0.05rem;
}

.accordion .card-header [aria-expanded="true"] {
  color: #f16e00;
}

.accordion .card-header [aria-expanded="true"]::after {
  transform: rotate(180deg);
}

.accordion .card-body {
  padding: 0 1.5rem 0 0;
  margin: 0 0 1.25rem;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.625rem 0;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.142857;
  list-style: none;
  background-color: #fff;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.625rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  /* rtl:ignore */
  padding-left: 0.625rem;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='7' height='10' viewBox='0 0 9 14'%3e%3cpath d='M9 2L7 0 0 7l7 7 2-2-5-5 5-5z'/%3e%3c/svg%3e");
  /* rtl:remove */
  transform: rotate(180deg);
}

.bg-dark .breadcrumb-item + .breadcrumb-item::before {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='7' height='10' viewBox='0 0 9 14'%3e%3cpath fill='%23fff' d='M9 2L7 0 0 7l7 7 2-2-5-5 5-5z'/%3e%3c/svg%3e");
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  margin: 1.25rem 0;
  padding-left: 0;
  list-style: none;
}

.page-link {
  display: flex;
  justify-content: center;
  min-width: 2.5rem;
  height: 2.5rem;
  padding: 0.625rem 0;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border: 0.125rem solid transparent;
  transition: outline-offset 0.2s ease-in-out;
  outline-offset: 0.375rem;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover, .page-link:focus {
  z-index: 2;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border-color: #ccc;
}

.page-link:focus {
  z-index: 3;
  outline: 0.125rem solid #000;
  outline-offset: 0;
}

.page-link.has-label {
  padding-top: calc(0.625rem - 1px);
  padding-bottom: calc(0.625rem + 1px);
}

.page-item:first-child .page-link, .page-item:last-child .page-link {
  color: #000;
  border-color: #000;
}

.page-item:first-child .page-link:hover, .page-item:first-child .page-link:focus, .page-item:last-child .page-link:hover, .page-item:last-child .page-link:focus {
  color: #fff;
  background-color: #000;
  outline-color: #000;
}

.page-item:first-child .page-link:active, .page-item:last-child .page-link:active {
  color: #000;
  background-color: #ff7900;
  border-color: #ff7900;
}

.page-item:first-child .page-link::before,
.page-item:last-child .page-link::after {
  display: inline-block;
  width: 0.5625rem;
  height: 0.9375rem;
  margin-top: 0.0625rem;
  content: "";
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 14'%3e%3cpath d='M9 2L7 0 0 7l7 7 2-2-5-5 5-5z'/%3e%3c/svg%3e") no-repeat 50% / 0.5625rem 0.9375rem;
}

.page-item:first-child {
  margin-left: 0;
}

.page-item:first-child .page-link {
  padding-right: 1.125rem;
  padding-left: 0.75rem;
}

.page-item:first-child .page-link::before {
  margin-right: -0.1875rem;
  /* rtl:raw:
       transform: rotate(180deg);
       */
}

.page-item:first-child .has-label::before {
  margin: 0.0625rem 0.375rem 0 0;
}

.page-item:last-child .page-link {
  padding-right: 0.75rem;
  padding-left: 1.125rem;
}

.page-item:last-child .page-link::after {
  margin-left: -0.1875rem;
  /* rtl:remove */
  transform: rotate(180deg);
}

.page-item:last-child .has-label::after {
  margin: 0.0625rem 0 0 0.375rem;
}

.page-item:first-child .page-link:hover::before,
.page-item:first-child .page-link:focus::before,
.page-item:last-child .page-link:hover::after,
.page-item:last-child .page-link:focus::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 14'%3e%3cpath fill='%23fff' d='M9 2L7 0 0 7l7 7 2-2-5-5 5-5z'/%3e%3c/svg%3e");
}

.page-item:first-child .page-link:active::before,
.page-item:last-child .page-link:active::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 14'%3e%3cpath d='M9 2L7 0 0 7l7 7 2-2-5-5 5-5z'/%3e%3c/svg%3e");
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.page-item.active .page-link:focus {
  background-color: #ff7900;
  border-color: #ff7900;
  outline-color: #ff7900;
}

.page-item .page-link:active {
  z-index: 1;
  color: #000;
  background-color: #ff7900;
  border-color: #ff7900;
  outline-color: #ff7900;
}

.page-item.disabled .page-link {
  color: #ccc;
  pointer-events: none;
  background-color: #fff;
  border-color: #ccc;
}

.page-item.disabled:first-child .page-link::before,
.page-item.disabled:last-child .page-link::after {
  opacity: .2;
}

@media (max-width: 767.98px) {
  .page-item {
    display: none;
  }
  .page-item.active, .page-item:nth-child(-n+3), .page-item:nth-last-child(-n+3) {
    display: list-item;
  }
  .page-item.active:not(:nth-child(-n+3)):not(:nth-last-child(-n+3)) ~ .page-item:nth-last-child(3) {
    display: none;
  }
}

@media (min-width: 480px) and (max-width: 1023.98px) {
  .page-item {
    display: none;
  }
  .page-item.active, .page-item:nth-child(-n+4), .page-item:nth-last-child(-n+4) {
    display: list-item;
  }
  .page-item.active:not(:nth-child(-n+4)):not(:nth-last-child(-n+4)) + .page-item:nth-last-child(4) {
    display: none;
  }
}

.page-item:nth-child(2):not(:last-child) .page-link {
  margin-left: 0.625rem;
}

.page-item:nth-last-child(2) .page-link {
  margin-right: 0.625rem;
}

.badge {
  display: inline-block;
  padding: 0.1875rem 0.625rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  transition: outline-offset 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge {
  text-decoration: none;
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
  margin-left: 0.3125rem;
}

.badge-pill {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.badge-primary {
  color: #000;
  background-color: #ff7900;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #000;
  background-color: #ff9433;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 rgba(255, 121, 0, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #000;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: black;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
}

.badge-success {
  color: #000;
  background-color: #32c832;
}

a.badge-success:hover, a.badge-success:focus {
  color: #000;
  background-color: #58d558;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 rgba(50, 200, 50, 0.5);
}

.badge-info {
  color: #000;
  background-color: #527edb;
}

a.badge-info:hover, a.badge-info:focus {
  color: #000;
  background-color: #7c9de4;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 rgba(82, 126, 219, 0.5);
}

.badge-warning {
  color: #000;
  background-color: #fc0;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #000;
  background-color: #ffd633;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 rgba(255, 204, 0, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #cd3c14;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #9f2e0f;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 rgba(205, 60, 20, 0.5);
}

.badge-light {
  color: #000;
  background-color: #ccc;
}

a.badge-light:hover, a.badge-light:focus {
  color: #000;
  background-color: #e6e6e6;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 rgba(204, 204, 204, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #000;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: black;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
}

.badge[href] {
  transition: outline-offset 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge[href] {
    transition: none;
  }
}

.badge[href]:focus {
  outline-color: #fff;
  outline-offset: -0.125rem;
}

.badge[href]:focus.badge-light {
  outline-color: #000;
}

.badge-xlg {
  font-size: 1.125rem;
  padding: 0.125rem 0.3125rem;
}

.badge-lg {
  font-size: 1rem;
  padding: 0.125rem 0.3125rem;
}

.jumbotron {
  padding: 2.5rem 1.25rem;
  margin-bottom: 2.5rem;
  color: #000;
  background-color: #fff;
}

@media (min-width: 480px) {
  .jumbotron {
    padding: 5rem 2.5rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
}

.alert {
  position: relative;
  display: flex;
  padding: 1rem 1.25rem;
  margin-bottom: 1.25rem;
  font-weight: 700;
  border: 0.125rem solid transparent;
}

.alert a {
  color: inherit;
}

.alert a:hover {
  color: #ff7900;
}

.bg-dark .alert a:hover {
  color: #f16e00;
}

.alert * p {
  margin: 0;
}

.alert p + p {
  font-weight: 400;
}

.alert-icon {
  width: 3rem;
  margin: -0.15625rem 0;
}

.alert-icon + * {
  max-width: calc(100% - (3rem + 2.5rem + 0.875rem));
  margin: 0.125rem 0;
}

.alert-icon::before {
  display: block;
  order: -1;
  min-width: 1.875rem;
  height: 1.625rem;
  margin-right: 1.625rem;
  content: "";
  background-repeat: no-repeat;
  background-size: 1.625rem;
}

.alert-heading {
  margin: 0;
  font-size: inherit;
  color: inherit;
}

.alert-heading + p {
  font-weight: 400;
}

.alert-dismissible .close {
  position: relative;
  z-index: 2;
  align-self: flex-start;
  width: 0.875rem;
  height: 0.875rem;
  padding: 1.25rem;
  margin: -.5rem -.75rem -.5rem auto;
  color: inherit;
  background-size: 0.875rem;
}

.alert-sm {
  padding: 0.625rem 0;
  font-size: 0.875rem;
  line-height: 1.142857;
  border: 0;
}

.alert-sm .alert-icon {
  width: 1.5rem;
  margin: 0.125rem 0;
}

.alert-sm .alert-icon::before {
  min-width: 1.0625rem;
  height: 1.0625rem;
  margin-right: 0.53125rem;
  background-size: 1.0625rem;
}

.alert-sm.alert-dismissible .close {
  margin: -0.625rem 0 -0.625rem auto;
}

.alert-success {
  border-color: #32c832;
}

.alert-info {
  border-color: #527edb;
}

.alert-warning {
  border-color: #fc0;
}

.alert-danger {
  border-color: #cd3c14;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1.875rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1.875rem;
  overflow: hidden;
  line-height: 0;
  font-size: 1rem;
  background-color: #eee;
}

.progress-xs {
  height: 0.3125rem;
}

.progress-sm {
  height: 0.625rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  font-weight: 700;
  color: #000;
  text-indent: 0.625rem;
  white-space: nowrap;
  background-color: #ff7900;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1.875rem 1.875rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #000;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #ff7900;
  text-decoration: none;
  background-color: #f6f6f6;
}

.list-group-item-action:active {
  color: #000;
  background-color: #eee;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.875rem 1.25rem;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border: 0.125rem solid #ccc;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #ccc;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -0.125rem;
  border-top-width: 0.125rem;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 0.125rem;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -0.125rem;
  border-left-width: 0.125rem;
}

@media (min-width: 480px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 0.125rem;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -0.125rem;
    border-left-width: 0.125rem;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 0.125rem;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -0.125rem;
    border-left-width: 0.125rem;
  }
}

@media (min-width: 1024px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 0.125rem;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -0.125rem;
    border-left-width: 0.125rem;
  }
}

@media (min-width: 1280px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 0.125rem;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -0.125rem;
    border-left-width: 0.125rem;
  }
}

@media (min-width: 1440px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 0.125rem;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -0.125rem;
    border-left-width: 0.125rem;
  }
}

.list-group-flush > .list-group-item {
  border-width: 0 0 0.125rem;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #000;
  background-color: #ff7900;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #000;
  background-color: #e66d00;
}

.list-group-item-primary.list-group-item-action.active {
  color: #000;
  background-color: #000;
  border-color: #000;
}

.list-group-item-secondary {
  color: #fff;
  background-color: black;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #fff;
  background-color: black;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.list-group-item-success {
  color: #000;
  background-color: #32c832;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #000;
  background-color: #2db42d;
}

.list-group-item-success.list-group-item-action.active {
  color: #000;
  background-color: #000;
  border-color: #000;
}

.list-group-item-info {
  color: #000;
  background-color: #527edb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #000;
  background-color: #3d6ed7;
}

.list-group-item-info.list-group-item-action.active {
  color: #000;
  background-color: #000;
  border-color: #000;
}

.list-group-item-warning {
  color: #000;
  background-color: #ffcc00;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #000;
  background-color: #e6b800;
}

.list-group-item-warning.list-group-item-action.active {
  color: #000;
  background-color: #000;
  border-color: #000;
}

.list-group-item-danger {
  color: #fff;
  background-color: #cd3c14;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #fff;
  background-color: #b63512;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.list-group-item-light {
  color: #000;
  background-color: #cccccc;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #000;
  background-color: #bfbfbf;
}

.list-group-item-light.list-group-item-action.active {
  color: #000;
  background-color: #000;
  border-color: #000;
}

.list-group-item-dark {
  color: #fff;
  background-color: black;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #fff;
  background-color: black;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.close {
  float: right;
  width: 2.75rem;
  height: 2.75rem;
  padding: 0;
  margin-right: 0.625rem;
  transition: outline-offset 0.2s ease-in-out;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23000' stroke-width='5' stroke-linecap='round' d='M15 17.121l-8.132 8.132-2.121-2.12L12.879 15 4.747 6.868l2.12-2.121L15 12.879l8.132-8.132 2.12 2.121L17.122 15l8.132 8.132-2.121 2.12L15 17.123z'/%3e%3c/svg%3e") no-repeat 50%/1rem;
  border: 0;
  outline-offset: 0.625rem;
  opacity: 1;
}

@media (prefers-reduced-motion: reduce) {
  .close {
    transition: none;
  }
}

.bg-dark .close,
.navbar-dark .close {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath fill='%23fff' stroke='%23fff' stroke-width='5' stroke-linecap='round' d='M15 17.121l-8.132 8.132-2.121-2.12L12.879 15 4.747 6.868l2.12-2.121L15 12.879l8.132-8.132 2.12 2.121L17.122 15l8.132 8.132-2.121 2.12L15 17.123z'/%3e%3c/svg%3e");
}

.bg-dark .close:hover, .bg-dark .close:focus,
.navbar-dark .close:hover,
.navbar-dark .close:focus {
  outline-color: #666;
}

.close:hover, .close:focus {
  outline: 0.125rem solid #ccc;
  outline-offset: -0.125rem;
}

.close:hover:not(:disabled):not(.disabled), .close:focus:not(:disabled):not(.disabled) {
  opacity: 1;
}

.close:hover {
  transition: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  opacity: 0;
}

.toast:not(:last-child) {
  margin-bottom: 0.625rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.3125rem 0.3125rem 0.3125rem 0.625rem;
  color: #666;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-header .close {
  width: 1.875rem;
  height: 1.875rem;
}

.toast-body {
  padding: 0.625rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.625rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1.25rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1.25rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  padding: 1.25rem;
  margin: 1.25rem 0 0.625rem;
  overflow-y: auto;
  border: 0.125rem solid #ccc;
  border-width: 0.125rem 0;
}

.modal-dialog:not(.modal-dialog-scrollable) .modal-body > :last-child {
  margin-bottom: 0;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1.25rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1.25rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.25rem 0;
  color: #000;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.125rem solid #ccc;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #333;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 1.25rem;
}

.modal-header .close {
  position: relative;
  z-index: 2;
  margin: -0.6875rem -0.6875rem -0.6875rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.125;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0.625rem 1.25rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.625rem 0.9375rem 0;
}

.modal-footer > * {
  margin: 0 0.3125rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 460px;
    margin: 1.875rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.75rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.75rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.75rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.75rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 1024px) {
  .modal-lg,
  .modal-xl {
    max-width: 700px;
  }
}

@media (min-width: 1280px) {
  .modal-xl {
    max-width: 940px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0.3125rem;
  font-family: HelvNeueOrange/*rtl:insert:Arabic*/, Helvetica Neue, Helvetica, Arial, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  font-weight: 700;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.625rem;
  height: 0.3125rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.3125rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.3125rem 0.3125rem 0;
  border-top-color: #ddd;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.3125rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.3125rem;
  height: 0.625rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.3125rem 0.3125rem 0.3125rem 0;
  border-right-color: #ddd;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.3125rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.3125rem 0.3125rem;
  border-bottom-color: #ddd;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.3125rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.3125rem;
  height: 0.625rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.3125rem 0 0.3125rem 0.3125rem;
  border-left-color: #ddd;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.625rem;
  color: #000;
  background-color: #ddd;
}

.popover {
  position: absolute;
  top: 0;
  /* rtl:raw:
  right: auto;
  */
  /* rtl:ignore */
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: HelvNeueOrange/*rtl:insert:Arabic*/, Helvetica Neue, Helvetica, Arial, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  font-weight: 700;
  word-wrap: break-word;
  background-color: #ddd;
  background-clip: padding-box;
  border: 0.125rem solid #ddd;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 0.625rem;
  height: 0.3125rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.3125rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: -0.4375rem;
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.3125rem 0.3125rem 0;
  border-top-color: #ddd;
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 0.125rem;
  border-width: 0.3125rem 0.3125rem 0;
  border-top-color: #ddd;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.3125rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: -0.4375rem;
  width: 0.3125rem;
  height: 0.625rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.3125rem 0.3125rem 0.3125rem 0;
  border-right-color: #ddd;
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 0.125rem;
  border-width: 0.3125rem 0.3125rem 0.3125rem 0;
  border-right-color: #ddd;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.3125rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: -0.4375rem;
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.3125rem 0.3125rem 0.3125rem;
  border-bottom-color: #ddd;
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 0.125rem;
  border-width: 0 0.3125rem 0.3125rem 0.3125rem;
  border-bottom-color: #ddd;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 0.625rem;
  margin-left: -0.3125rem;
  content: "";
  border-bottom: 0.125rem solid #ddd;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.3125rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: -0.4375rem;
  width: 0.3125rem;
  height: 0.625rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.3125rem 0 0.3125rem 0.3125rem;
  border-left-color: #ddd;
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 0.125rem;
  border-width: 0.3125rem 0 0.3125rem 0.3125rem;
  border-left-color: #ddd;
}

.popover-header {
  padding: 0.625rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #ddd;
  border-bottom: 0.125rem solid #d0d0d0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.625rem;
  color: #000;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item img {
  display: block;
  width: 100%;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  padding: 0;
  color: #000;
  text-align: center;
  background: none;
  border: 0;
  transition: opacity 0.15s ease, outline-offset 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #000;
  text-decoration: none;
  opacity: 0.9;
}

.carousel-control-prev:focus,
.carousel-control-next:focus {
  outline: 0.125rem solid #000;
}

.js-focus-visible .carousel-control-prev:focus:not([data-focus-visible-added]), .js-focus-visible
.carousel-control-next:focus:not([data-focus-visible-added]) {
  outline: 0;
}

.carousel-control-prev {
  /* rtl:raw:
  right: auto;
   */
  left: 0;
}

.carousel-control-next {
  right: 0;
  /* rtl:raw:
  left: auto;
   */
}

.carousel-control-pause {
  position: relative;
  z-index: 2;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  background: rgba(255, 255, 255, 0.5) url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 14'%3e%3cpath d='M9 2L7 0 0 7l7 7 2-2-5-5 5-5z'/%3e%3c/svg%3e") no-repeat calc(50% - .125rem) 50%/1.5rem 1.5rem !important;
  border-radius: 50%;
}

:hover:not(:active) > .carousel-control-prev-icon,
:focus:not(:active) > .carousel-control-prev-icon, :hover:not(:active) >
.carousel-control-next-icon,
:focus:not(:active) >
.carousel-control-next-icon {
  background-color: #000 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 14'%3e%3cpath fill='%23fff' d='M9 2L7 0 0 7l7 7 2-2-5-5 5-5z'/%3e%3c/svg%3e") !important;
}

:active > .carousel-control-prev-icon, :active >
.carousel-control-next-icon {
  background-color: #ff7900 !important;
}

/* rtl:begin:remove */
.carousel-control-next-icon {
  transform: rotate(180deg);
}

/* rtl:end:remove */
.carousel-indicators {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding: 0.375rem 0;
  list-style: none;
  background-color: rgba(255, 255, 255, 0.5);
  transform: translateX(-50%);
  border-radius: 1.25rem;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.375rem;
  margin-left: 0.375rem;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000;
  background-clip: padding-box;
  border: 0.125rem solid transparent;
  transition: opacity 0.6s ease;
  border-radius: 50%;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators li:hover, .carousel-indicators li:focus {
  border-color: #000;
}

.carousel-indicators .active {
  background-color: transparent;
  border-color: #000;
}

.carousel-indicators .active:hover, .carousel-indicators .active:focus {
  background-color: #000;
}

.carousel-caption, .swiper-slide .card-body {
  position: absolute;
  bottom: 1.875rem;
  left: 1.875rem;
  z-index: 10;
  padding: 1.25rem;
  margin-bottom: 0.625rem;
  color: #000;
  background-color: #fff;
}

.carousel-caption *, .swiper-slide .card-body * {
  margin: 0;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  vertical-align: -0.125em;
  border: 0.375rem solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

.spinner-border.text-light {
  background-color: transparent;
}

.spinner-border-sm {
  width: 1.25rem;
  height: 1.25rem;
  border-width: 0.25rem;
}

.spinner-border-lg {
  width: 5rem;
  height: 5rem;
  border-width: 0.5rem;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  vertical-align: -0.125em;
  background-color: currentcolor !important;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1.25rem;
  height: 1.25rem;
}

.spinner-grow-lg {
  width: 5rem;
  height: 5rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  color: #000 !important;
  background-color: #ff7900 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #ff9433 !important;
}

.bg-secondary {
  color: #fff !important;
  background-color: #000 !important;
}

.bg-secondary a {
  color: #fff;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: black !important;
}

.bg-light {
  color: #000 !important;
  background-color: #ccc !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-dark {
  color: #fff !important;
  background-color: #000 !important;
}

.bg-dark a {
  color: #fff;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: black !important;
}

.bg-success {
  color: #000 !important;
  background-color: #50be87 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #75cca1 !important;
}

.bg-info {
  color: #000 !important;
  background-color: #4bb4e6 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #78c7ec !important;
}

.bg-warning {
  color: #000 !important;
  background-color: #ffd200 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffdb33 !important;
}

.bg-danger {
  color: #000 !important;
  background-color: #ffb4e6 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ffe7f7 !important;
}

.bg-purple {
  color: #000 !important;
  background-color: #a885d8 !important;
}

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #c4ace4 !important;
}

.bg-supporting-green {
  color: #000 !important;
  background-color: #50be87 !important;
}

a.bg-supporting-green:hover, a.bg-supporting-green:focus,
button.bg-supporting-green:hover,
button.bg-supporting-green:focus {
  background-color: #75cca1 !important;
}

.bg-supporting-blue {
  color: #000 !important;
  background-color: #4bb4e6 !important;
}

a.bg-supporting-blue:hover, a.bg-supporting-blue:focus,
button.bg-supporting-blue:hover,
button.bg-supporting-blue:focus {
  background-color: #78c7ec !important;
}

.bg-supporting-yellow {
  color: #000 !important;
  background-color: #ffd200 !important;
}

a.bg-supporting-yellow:hover, a.bg-supporting-yellow:focus,
button.bg-supporting-yellow:hover,
button.bg-supporting-yellow:focus {
  background-color: #ffdb33 !important;
}

.bg-supporting-pink {
  color: #000 !important;
  background-color: #ffb4e6 !important;
}

a.bg-supporting-pink:hover, a.bg-supporting-pink:focus,
button.bg-supporting-pink:hover,
button.bg-supporting-pink:focus {
  background-color: #ffe7f7 !important;
}

.bg-supporting-purple {
  color: #000 !important;
  background-color: #a885d8 !important;
}

a.bg-supporting-purple:hover, a.bg-supporting-purple:focus,
button.bg-supporting-purple:hover,
button.bg-supporting-purple:focus {
  background-color: #c4ace4 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 0.125rem solid #000 !important;
}

.border-top {
  border-top: 0.125rem solid #000 !important;
}

.border-right {
  border-right: 0.125rem solid #000 !important;
}

.border-bottom {
  border-bottom: 0.125rem solid #000 !important;
}

.border-left {
  border-left: 0.125rem solid #000 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #ff7900 !important;
}

.border-secondary {
  border-color: #000 !important;
}

.border-success {
  border-color: #32c832 !important;
}

.border-info {
  border-color: #527edb !important;
}

.border-warning {
  border-color: #fc0 !important;
}

.border-danger {
  border-color: #cd3c14 !important;
}

.border-light {
  border-color: #ccc !important;
}

.border-dark {
  border-color: #000 !important;
}

.border-100 {
  border-color: #fafafa !important;
}

.border-200 {
  border-color: #f6f6f6 !important;
}

.border-300 {
  border-color: #eee !important;
}

.border-400 {
  border-color: #ddd !important;
}

.border-500 {
  border-color: #ccc !important;
}

.border-600 {
  border-color: #999 !important;
}

.border-700 {
  border-color: #666 !important;
}

.border-800 {
  border-color: #595959 !important;
}

.border-900 {
  border-color: #333 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 480px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1024px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1280px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1440px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-9by16::before {
  padding-top: 177.777778%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 480px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1024px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1280px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1440px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 480px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 1024px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1280px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1440px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only, .valid-feedback {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.3125rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.3125rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.3125rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.3125rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.3125rem !important;
}

.m-2 {
  margin: 0.625rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.625rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.625rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.625rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.625rem !important;
}

.m-3 {
  margin: 1.25rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1.25rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1.25rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1.25rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1.25rem !important;
}

.m-4 {
  margin: 1.875rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.875rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.875rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.875rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.875rem !important;
}

.m-5 {
  margin: 3.75rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3.75rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3.75rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3.75rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3.75rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.3125rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.3125rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.3125rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.3125rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.3125rem !important;
}

.p-2 {
  padding: 0.625rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.625rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.625rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.625rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.625rem !important;
}

.p-3 {
  padding: 1.25rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1.25rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1.25rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1.25rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1.25rem !important;
}

.p-4 {
  padding: 1.875rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.875rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.875rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.875rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.875rem !important;
}

.p-5 {
  padding: 3.75rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3.75rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3.75rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3.75rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3.75rem !important;
}

.m-n1 {
  margin: -0.3125rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.3125rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.3125rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.3125rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.3125rem !important;
}

.m-n2 {
  margin: -0.625rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.625rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.625rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.625rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.625rem !important;
}

.m-n3 {
  margin: -1.25rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1.25rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1.25rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1.25rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1.25rem !important;
}

.m-n4 {
  margin: -1.875rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.875rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.875rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.875rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.875rem !important;
}

.m-n5 {
  margin: -3.75rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3.75rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3.75rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3.75rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3.75rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 480px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.3125rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.3125rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.3125rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.3125rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.3125rem !important;
  }
  .m-sm-2 {
    margin: 0.625rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.625rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.625rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.625rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.625rem !important;
  }
  .m-sm-3 {
    margin: 1.25rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.25rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.25rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.25rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.25rem !important;
  }
  .m-sm-4 {
    margin: 1.875rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.875rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.875rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.875rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.875rem !important;
  }
  .m-sm-5 {
    margin: 3.75rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3.75rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3.75rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3.75rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3.75rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.3125rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.3125rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.3125rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.3125rem !important;
  }
  .p-sm-2 {
    padding: 0.625rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.625rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.625rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.625rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.625rem !important;
  }
  .p-sm-3 {
    padding: 1.25rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.25rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.25rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.25rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.25rem !important;
  }
  .p-sm-4 {
    padding: 1.875rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.875rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.875rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.875rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.875rem !important;
  }
  .p-sm-5 {
    padding: 3.75rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3.75rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3.75rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3.75rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3.75rem !important;
  }
  .m-sm-n1 {
    margin: -0.3125rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.3125rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.3125rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.3125rem !important;
  }
  .m-sm-n2 {
    margin: -0.625rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.625rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.625rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.625rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.625rem !important;
  }
  .m-sm-n3 {
    margin: -1.25rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1.25rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1.25rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1.25rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1.25rem !important;
  }
  .m-sm-n4 {
    margin: -1.875rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.875rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.875rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.875rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.875rem !important;
  }
  .m-sm-n5 {
    margin: -3.75rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3.75rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3.75rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3.75rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3.75rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.3125rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.3125rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.3125rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.3125rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.3125rem !important;
  }
  .m-md-2 {
    margin: 0.625rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.625rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.625rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.625rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.625rem !important;
  }
  .m-md-3 {
    margin: 1.25rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.25rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.25rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.25rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.25rem !important;
  }
  .m-md-4 {
    margin: 1.875rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.875rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.875rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.875rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.875rem !important;
  }
  .m-md-5 {
    margin: 3.75rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3.75rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3.75rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3.75rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3.75rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.3125rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.3125rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.3125rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.3125rem !important;
  }
  .p-md-2 {
    padding: 0.625rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.625rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.625rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.625rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.625rem !important;
  }
  .p-md-3 {
    padding: 1.25rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.25rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.25rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.25rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.25rem !important;
  }
  .p-md-4 {
    padding: 1.875rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.875rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.875rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.875rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.875rem !important;
  }
  .p-md-5 {
    padding: 3.75rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3.75rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3.75rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3.75rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3.75rem !important;
  }
  .m-md-n1 {
    margin: -0.3125rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.3125rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.3125rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.3125rem !important;
  }
  .m-md-n2 {
    margin: -0.625rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.625rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.625rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.625rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.625rem !important;
  }
  .m-md-n3 {
    margin: -1.25rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1.25rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1.25rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1.25rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1.25rem !important;
  }
  .m-md-n4 {
    margin: -1.875rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.875rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.875rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.875rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.875rem !important;
  }
  .m-md-n5 {
    margin: -3.75rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3.75rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3.75rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3.75rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3.75rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1024px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.3125rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.3125rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.3125rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.3125rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.3125rem !important;
  }
  .m-lg-2 {
    margin: 0.625rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.625rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.625rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.625rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.625rem !important;
  }
  .m-lg-3 {
    margin: 1.25rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.25rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.25rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.25rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.25rem !important;
  }
  .m-lg-4 {
    margin: 1.875rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.875rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.875rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.875rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.875rem !important;
  }
  .m-lg-5 {
    margin: 3.75rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3.75rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3.75rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3.75rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3.75rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.3125rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.3125rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.3125rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.3125rem !important;
  }
  .p-lg-2 {
    padding: 0.625rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.625rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.625rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.625rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.625rem !important;
  }
  .p-lg-3 {
    padding: 1.25rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.25rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.25rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.25rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.25rem !important;
  }
  .p-lg-4 {
    padding: 1.875rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.875rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.875rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.875rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.875rem !important;
  }
  .p-lg-5 {
    padding: 3.75rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3.75rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3.75rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3.75rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3.75rem !important;
  }
  .m-lg-n1 {
    margin: -0.3125rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.3125rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.3125rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.3125rem !important;
  }
  .m-lg-n2 {
    margin: -0.625rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.625rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.625rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.625rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.625rem !important;
  }
  .m-lg-n3 {
    margin: -1.25rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1.25rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1.25rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1.25rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1.25rem !important;
  }
  .m-lg-n4 {
    margin: -1.875rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.875rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.875rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.875rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.875rem !important;
  }
  .m-lg-n5 {
    margin: -3.75rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3.75rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3.75rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3.75rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3.75rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1280px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.3125rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.3125rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.3125rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.3125rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.3125rem !important;
  }
  .m-xl-2 {
    margin: 0.625rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.625rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.625rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.625rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.625rem !important;
  }
  .m-xl-3 {
    margin: 1.25rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.25rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.25rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.25rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.25rem !important;
  }
  .m-xl-4 {
    margin: 1.875rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.875rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.875rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.875rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.875rem !important;
  }
  .m-xl-5 {
    margin: 3.75rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3.75rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3.75rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3.75rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3.75rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.3125rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.3125rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.3125rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.3125rem !important;
  }
  .p-xl-2 {
    padding: 0.625rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.625rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.625rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.625rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.625rem !important;
  }
  .p-xl-3 {
    padding: 1.25rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.25rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.25rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.25rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.25rem !important;
  }
  .p-xl-4 {
    padding: 1.875rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.875rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.875rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.875rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.875rem !important;
  }
  .p-xl-5 {
    padding: 3.75rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3.75rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3.75rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3.75rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3.75rem !important;
  }
  .m-xl-n1 {
    margin: -0.3125rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.3125rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.3125rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.3125rem !important;
  }
  .m-xl-n2 {
    margin: -0.625rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.625rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.625rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.625rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.625rem !important;
  }
  .m-xl-n3 {
    margin: -1.25rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1.25rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1.25rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1.25rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1.25rem !important;
  }
  .m-xl-n4 {
    margin: -1.875rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.875rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.875rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.875rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.875rem !important;
  }
  .m-xl-n5 {
    margin: -3.75rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3.75rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3.75rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3.75rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3.75rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1440px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.3125rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.3125rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.3125rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.3125rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.3125rem !important;
  }
  .m-xxl-2 {
    margin: 0.625rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.625rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.625rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.625rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.625rem !important;
  }
  .m-xxl-3 {
    margin: 1.25rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1.25rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1.25rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1.25rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1.25rem !important;
  }
  .m-xxl-4 {
    margin: 1.875rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.875rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.875rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.875rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.875rem !important;
  }
  .m-xxl-5 {
    margin: 3.75rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3.75rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3.75rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3.75rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3.75rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.3125rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.3125rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.3125rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.3125rem !important;
  }
  .p-xxl-2 {
    padding: 0.625rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.625rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.625rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.625rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.625rem !important;
  }
  .p-xxl-3 {
    padding: 1.25rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1.25rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1.25rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1.25rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1.25rem !important;
  }
  .p-xxl-4 {
    padding: 1.875rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.875rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.875rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.875rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.875rem !important;
  }
  .p-xxl-5 {
    padding: 3.75rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3.75rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3.75rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3.75rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3.75rem !important;
  }
  .m-xxl-n1 {
    margin: -0.3125rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.3125rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.3125rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.3125rem !important;
  }
  .m-xxl-n2 {
    margin: -0.625rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.625rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.625rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.625rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.625rem !important;
  }
  .m-xxl-n3 {
    margin: -1.25rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1.25rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1.25rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1.25rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1.25rem !important;
  }
  .m-xxl-n4 {
    margin: -1.875rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.875rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.875rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.875rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.875rem !important;
  }
  .m-xxl-n5 {
    margin: -3.75rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3.75rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3.75rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3.75rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3.75rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 480px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 1024px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1280px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1440px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.ll-sm {
  max-width: 40ch;
}

.ll-md {
  max-width: 80ch;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-weight: 700 !important;
}

.text-white {
  color: #fff !important;
  background-color: #000;
}

.text-secondary {
  color: #000 !important;
  background-color: #fff;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: black !important;
}

.text-light {
  color: #ccc !important;
  background-color: #000;
}

a.text-light:hover, a.text-light:focus {
  color: #f2f2f2 !important;
}

.text-dark {
  color: #000 !important;
  background-color: #fff;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-primary {
  color: #f16e00 !important;
}

.text-primary a.text-primary:hover, .text-primary a.text-primary:focus {
  color: #a54b00 !important;
}

.text-body {
  color: #000 !important;
  background-color: #fff;
}

.text-muted {
  color: #666 !important;
  background-color: #fff;
}

.bg-dark .text-primary,
.table-dark .text-primary,
.navbar-dark .text-primary {
  color: #ff7900 !important;
  background-color: #000;
}

.bg-dark a.text-primary:hover, .bg-dark a.text-primary:focus,
.table-dark a.text-primary:hover,
.table-dark a.text-primary:focus,
.navbar-dark a.text-primary:hover,
.navbar-dark a.text-primary:focus {
  color: #ffa14d !important;
}

.bg-dark .text-body,
.table-dark .text-body,
.navbar-dark .text-body {
  color: #fff !important;
  background-color: #000;
}

.bg-dark .text-muted,
.table-dark .text-muted,
.navbar-dark .text-muted {
  color: #999 !important;
  background-color: #000;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 0.125rem solid #ccc;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 1024px !important;
  }
  .container {
    min-width: 1024px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 0.125rem solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #eee !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #ccc;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #ccc;
  }
  .row,
  .form-row {
    display: block !important;
  }
}

.o-switch .toggle {
  position: relative;
  display: block;
  width: auto;
  min-width: 3.75rem;
  height: 1.875rem;
  margin: 0.3125rem 0;
  background: #666;
  border: 0.125rem solid #666;
  outline-offset: 0.25rem;
  transition: outline-offset 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .o-switch .toggle {
    transition: none;
  }
}

.o-switch .toggle::before {
  position: absolute;
  left: 0;
  width: 1.625rem;
  height: 1.625rem;
  content: "";
  background: #fff;
}

.o-switch .on,
.o-switch .off {
  display: none;
  line-height: 1.625rem;
  color: #fff;
  opacity: 0;
}

.o-switch .on::after,
.o-switch .off::after {
  position: absolute;
  top: .1875rem;
  background-position: 50%;
  background-size: 0.9375rem;
}

.o-switch .off {
  display: block;
  text-align: right;
  opacity: 1;
}

.o-switch .on::after {
  left: .25rem;
}

.o-switch .off::after {
  right: .25rem;
}

.o-switch .toggle-rounded,
.o-switch .toggle-rounded::before {
  border-radius: 1.625rem;
}

.o-switch .checkbox:focus + .toggle {
  outline: 0.125rem solid #666;
  outline-offset: -0.125rem;
}

.o-switch .checkbox:focus:checked + .toggle {
  outline-color: #ff7900;
}

.js-focus-visible .o-switch .checkbox:focus:not([data-focus-visible-added]) + .toggle {
  outline: 0;
}

.o-switch .checkbox:checked + .toggle {
  background: #ff7900;
  border-color: #ff7900;
}

.o-switch .checkbox:checked + .toggle .on {
  display: block;
  opacity: 1;
}

.o-switch .checkbox:checked + .toggle .off {
  display: none;
  opacity: 0;
}

.o-switch .checkbox:checked + .toggle::before {
  right: 0;
  left: auto;
}

.o-switch .success:checked + .toggle {
  background: #32c832;
  border-color: #32c832;
}

.o-switch .success:checked + .toggle .on {
  color: #000;
}

.o-switch .success:focus:checked + .toggle {
  outline-color: #32c832;
}

.form-group .o-switch .toggle {
  padding: 0 .375rem;
}

.form-group .o-switch .toggle-rounded {
  padding: 0 .875rem;
}

.o-switch.btn-group-toggle {
  display: inline-flex;
  justify-content: flex-start;
  border: 0.125rem solid #ccc;
}

.o-switch.btn-group-toggle .btn {
  min-width: 2.5rem;
  padding-right: 0;
  padding-left: 0;
  margin: -0.125rem 0;
  border: 0.125rem solid transparent;
  transition: outline-offset 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .o-switch.btn-group-toggle .btn {
    transition: none;
  }
}

.o-switch.btn-group-toggle .btn:first-child {
  margin-left: -0.125rem;
}

.o-switch.btn-group-toggle .btn:last-child {
  margin-right: -0.125rem;
}

.o-switch.btn-group-toggle .btn.active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.o-switch.btn-group-toggle .btn:focus, .o-switch.btn-group-toggle .btn.focus {
  outline: 0.125rem solid #ff7900 !important;
  outline-offset: -0.125rem;
}

.o-switch.btn-group-toggle .btn:active {
  border-color: #ff7900;
}

.o-switch.btn-group-toggle .btn:hover:not(:active) {
  color: #ff7900;
  background-color: transparent;
}

.o-switch.btn-group-toggle .btn:hover.active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.form-group input[type="radio"],
.form-group input[type="checkbox"] {
  width: auto;
}

.form-group .invalid-feedback {
  margin-top: .8125rem;
  font-weight: 700;
}

.form-group.top {
  display: flex;
  flex-direction: column;
}

.form-group.top .col-form-label {
  margin-bottom: 0;
}

.form-group.top .form-control {
  order: 2;
}

.form-group.top .invalid-feedback {
  order: 1;
  margin-top: 0;
  margin-bottom: .8125rem;
  line-height: 1;
}

.form-group.right {
  display: flex;
  flex-flow: row wrap;
}

.form-group.right label,
.form-group.right .col-form-label {
  min-width: 100%;
}

.form-group.right .form-control-message,
.form-group.right .invalid-feedback {
  max-width: 50%;
  margin-left: .625rem;
}

.form-group.row.top {
  flex-direction: row;
}

.form-group.row.top label,
.form-group.row.top .col-form-label {
  order: 1;
}

.form-group.row.top .form-control {
  order: 2;
  margin-top: 0;
}

.form-group.row.top .invalid-feedback {
  order: 0;
}

.form-group.row.right label,
.form-group.row.right .col-form-label {
  min-width: auto;
}

.form-group.row.right .invalid-feedback {
  align-self: center;
  margin-top: 0;
}

.form-group.is-required label::after {
  margin-left: 0.1875rem;
  font-weight: 700;
  color: #f16e00;
  content: "*";
}

.form-group .form-control:invalid {
  outline: 0;
  box-shadow: none;
}

select.form-control:not([size]):not([multiple]) {
  height: auto;
  padding-top: .4375rem;
  padding-bottom: .4375rem;
}

select.form-control:not([size]):not([multiple]).form-control-lg {
  padding-top: .65625rem;
  padding-bottom: .65625rem;
}

select.form-control:not([size]):not([multiple]).form-control-sm {
  padding-top: .1875rem;
  padding-bottom: .1875rem;
}

.has-feedback label ~ .invalid-feedback {
  top: 1.625;
}

.o-square-list {
  padding-left: 0;
  list-style-type: none;
}

.o-square-list ul {
  padding-left: 1rem;
  list-style-type: inherit;
}

.o-square-list li::before {
  margin-right: 0.625rem;
  font-size: 0.875rem;
  content: "\25A0";
}

.o-stepbar ol {
  display: flex;
  padding: 0;
  margin: 0;
  overflow: hidden;
  counter-reset: step;
}

.stepbar-item {
  display: flex;
  flex: 1;
  padding: 0.4375rem 0.625rem;
  margin-right: 0.125rem;
  font-weight: 700;
  text-align: center;
  list-style: none;
  counter-increment: step;
  background-color: #000;
}

.current {
  background-color: #ff7900;
}

.current .stepbar-link, .current .stepbar-link:hover, .current .stepbar-link:focus, .current .stepbar-link:active {
  color: #000;
}

.current ~ .stepbar-item {
  background-color: #ddd;
}

.current ~ .stepbar-item .stepbar-link, .current ~ .stepbar-item .stepbar-link:hover, .current ~ .stepbar-item .stepbar-link:focus, .current ~ .stepbar-item .stepbar-link:active {
  color: #000;
}

.stepbar-link {
  flex: 1 0 1.25ch;
  max-width: 1.25ch;
  margin: auto;
  overflow: hidden;
  font-size: 0.875rem;
  line-height: 1.142857;
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  outline-offset: 1.25rem;
}

.stepbar-link::before {
  content: counter(step) " ";
}

.stepbar-link:hover, .stepbar-link:focus, .stepbar-link:active {
  color: #fff;
}

.stepbar-link:focus {
  text-decoration: underline;
  outline-offset: .0625rem;
}

@media (min-width: 480px) {
  .stepbar-item {
    position: relative;
    max-width: 3.75rem;
    padding: 0.4375rem 0.25rem 0.4375rem 1.0625rem;
    margin: 0;
  }
  .stepbar-item::after {
    position: absolute;
    top: -1px;
    right: 1px;
    z-index: 2;
    width: 0.8125rem;
    height: 2.017857rem;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 40'%3e%3cpath fill='%23000' d='M0 0l14 20L0 40z'/%3e%3cpath fill='%23fff' d='M3 0H0l14 20L0 40h3l14-20z'/%3e%3c/svg%3e");
    background-size: 0.8125rem 2.017857rem;
    transform: translateX(/*rtl:insert:-*/0.8125rem) /*rtl:insert:rotate(180deg)*/;
  }
  .stepbar-item:first-child {
    padding-left: 0.40625rem;
  }
  .stepbar-item:last-child {
    padding-right: 0.40625rem;
  }
  .stepbar-item:last-child::after {
    display: none;
  }
  .current,
  .current .stepbar-link {
    max-width: none;
  }
  .current::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 40'%3e%3cpath fill='%23ff7900' d='M0 0l14 20L0 40z'/%3e%3cpath fill='%23fff' d='M3 0H0l14 20L0 40h3l14-20z'/%3e%3c/svg%3e");
  }
  .current ~ .stepbar-item::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 40'%3e%3cpath fill='%23ddd' d='M0 0l14 20L0 40z'/%3e%3cpath fill='%23fff' d='M3 0H0l14 20L0 40h3l14-20z'/%3e%3c/svg%3e");
  }
  .current .stepbar-link::before {
    content: counter(step) ". ";
  }
}

@media (min-width: 1280px) {
  .stepbar-item,
  .stepbar-link {
    max-width: none;
  }
  .stepbar-link::before {
    content: counter(step) ". ";
  }
}

.o-link-arrow {
  font-weight: 700;
  text-decoration: none;
}

.o-link-arrow::after, .o-link-arrow::before {
  display: inline-block;
  width: 0.4375rem;
  height: 0.625rem;
  vertical-align: middle;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 14'%3e%3cpath d='M9 2L7 0 0 7l7 7 2-2-5-5 5-5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
}

.o-link-arrow:not(.back)::after {
  margin-left: 0.625rem;
  content: "";
  /* rtl:remove */
  transform: rotate(180deg);
}

.o-link-arrow.back::before {
  margin-right: 0.625rem;
  content: "";
  /* rtl:raw:
    transform: rotate(180deg);
    */
}

.o-link-arrow:hover, .o-link-arrow:focus {
  text-decoration: underline;
}

.o-link-arrow:hover::after, .o-link-arrow:hover::before, .o-link-arrow:focus::after, .o-link-arrow:focus::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 14'%3e%3cpath fill='%23ff7900' d='M9 2L7 0 0 7l7 7 2-2-5-5 5-5z'/%3e%3c/svg%3e");
}

.nav-heading {
  display: none;
}

.mega-menu {
  overflow: hidden;
  /* rtl:raw:
  .back::before {
    transform: none;
  }

  [aria-haspopup]::after {
    transform: rotate(180deg);
  }
  */
}

.mega-menu [class*="container"] > .navbar-nav {
  display: block;
  flex-wrap: wrap;
  width: 100%;
}

.mega-menu > .navbar-nav {
  transform: translateX(0);
  will-change: transform;
  transition: transform 0.5s;
}

@media (prefers-reduced-motion: reduce) {
  .mega-menu > .navbar-nav {
    transition: none;
  }
}

.mega-menu .nav-item {
  position: static;
  padding: 0;
}

.mega-menu .nav-link {
  display: flex;
  padding: 0.875rem 0;
  font-size: 1rem;
}

.mega-menu .nav-link::after {
  display: block;
  align-self: center;
  margin-left: auto;
}

.mega-menu .nav-link[aria-expanded="true"] {
  color: #ff7900;
}

.mega-menu .close {
  margin-right: 0;
}

.mega-menu [aria-expanded="true"]::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff7900' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
  transform: rotateZ(180deg);
}

.mega-menu [data-toggle="collapse"]::after {
  width: 1rem;
  height: .4375rem;
  content: "";
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 7'%3e%3cpath fill='%23fff' d='M7 7L0 0h14L7 7z'/%3e%3c/svg%3e") no-repeat 50%/0.875rem 1rem;
}

.mega-menu [data-toggle="collapse"]:hover::after, .mega-menu [data-toggle="collapse"]:focus::after, .mega-menu [data-toggle="collapse"]:active::after, .mega-menu [data-toggle="collapse"].active::after, .mega-menu [data-toggle="collapse"][aria-expanded="true"]::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 7'%3e%3cpath fill='%23ff7900' d='M7 7L0 0h14L7 7z'/%3e%3c/svg%3e");
}

.mega-menu [data-toggle="collapse"].active {
  position: static;
}

.mega-menu [data-toggle="collapse"].active::before {
  right: 0;
  bottom: -0.0625rem;
  left: 0;
  z-index: 1;
  height: 0.0625rem;
}

.mega-menu .back::before,
.mega-menu [aria-haspopup]::after {
  width: 0.875rem;
  height: 0.875rem;
  content: "";
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") no-repeat 0.5;
}

.mega-menu .back:hover::before,
.mega-menu .back:focus::before,
.mega-menu .back:active::before,
.mega-menu [aria-haspopup]:hover::after,
.mega-menu [aria-haspopup]:focus::after,
.mega-menu [aria-haspopup]:active::after,
.mega-menu [aria-haspopup].active::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff7900' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.mega-menu .back::before {
  display: block;
  align-self: center;
  margin: 0 0.3125rem 0 -1.25rem;
  transform: rotateZ(-180deg);
}

.mega-menu-panel[role="menu"] [class*="container"] {
  max-width: 100%;
}

.mega-menu-panel .navbar-nav .navbar-nav {
  flex-direction: column;
}

.mega-menu-panel .nav-link.active {
  text-decoration: underline;
  text-decoration-thickness: 0.125rem;
  text-underline-offset: 0.25rem;
}

.mega-menu-panel .nav-link.active::before {
  display: none;
}

.mega-menu-panel [data-toggle="collapse"] {
  display: none;
}

.mega-menu-panel .nav-heading {
  display: flex;
  padding: 0.875rem 0 0.875rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.1;
  letter-spacing: -0.025rem;
}

.mega-menu-panel [role="menu"] {
  position: absolute;
  top: 0;
  left: 100%;
  display: none;
  width: 100%;
  transition: none;
}

.mega-menu-panel [role="menu"] .nav-link {
  padding-left: 1.25rem;
}

.mega-menu-panel [role="menu"] .nav-item:nth-child(2) {
  border: 0;
}

.mega-menu-panel .all {
  flex: 1;
  border-top: 0.0625rem solid #333;
}

@media (min-width: 480px) {
  .navbar-expand-sm .mega-menu {
    overflow: visible;
  }
  .navbar-expand-sm .mega-menu [data-toggle="collapse"] {
    display: flex;
  }
  .navbar-expand-sm .mega-menu [data-toggle="collapse"]::after {
    display: none;
  }
  .navbar-expand-sm .mega-menu [data-toggle="collapse"].active {
    position: relative;
  }
  .navbar-expand-sm .mega-menu [data-toggle="collapse"].active::before {
    right: 0.625rem;
    bottom: 0;
    left: 0.625rem;
    height: 0.25rem;
  }
  .navbar-expand-sm .mega-menu > .navbar-nav {
    transform: none;
    will-change: auto;
  }
  .navbar-expand-sm .mega-menu-panel {
    position: absolute;
    top: 6.25rem;
    left: 0;
    z-index: 1030;
    width: 100%;
    padding: 1.25rem 0 3.75rem;
    background-color: #000;
  }
  .navbar-expand-sm .mega-menu-panel .container-md > .navbar-nav {
    display: flex;
    padding: 0 0.625rem;
  }
  .navbar-expand-sm .mega-menu-panel .col {
    padding: 1rem 0;
  }
  .navbar-expand-sm .mega-menu-panel > .container-md + .container-md .nav-link:not(:only-child) {
    padding: 0 0 0.625rem;
    margin: 0 1.25rem 0.625rem 0;
    border-bottom: 0.0625rem solid #666;
  }
  .navbar-expand-sm .mega-menu-panel .navbar-nav .navbar-nav .nav-link {
    padding: 0.3125rem 3.75rem 0.3125rem 0;
  }
  .navbar-expand-sm .mega-menu-panel .back,
  .navbar-expand-sm .mega-menu-panel .nav-heading {
    display: none;
  }
  .navbar-expand-sm .mega-menu-panel .all {
    border: 0;
  }
  .navbar-expand-sm.minimized .mega-menu-panel {
    top: 3.75rem;
  }
  .navbar-expand-sm:not(.minimized) .mega-menu .nav-link {
    padding: 0.875rem 0.625rem;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .mega-menu {
    overflow: visible;
  }
  .navbar-expand-md .mega-menu [data-toggle="collapse"] {
    display: flex;
  }
  .navbar-expand-md .mega-menu [data-toggle="collapse"]::after {
    display: none;
  }
  .navbar-expand-md .mega-menu [data-toggle="collapse"].active {
    position: relative;
  }
  .navbar-expand-md .mega-menu [data-toggle="collapse"].active::before {
    right: 0.625rem;
    bottom: 0;
    left: 0.625rem;
    height: 0.25rem;
  }
  .navbar-expand-md .mega-menu > .navbar-nav {
    transform: none;
    will-change: auto;
  }
  .navbar-expand-md .mega-menu-panel {
    position: absolute;
    top: 6.25rem;
    left: 0;
    z-index: 1030;
    width: 100%;
    padding: 1.25rem 0 3.75rem;
    background-color: #000;
  }
  .navbar-expand-md .mega-menu-panel .container-lg > .navbar-nav {
    display: flex;
    padding: 0 0.625rem;
  }
  .navbar-expand-md .mega-menu-panel .col {
    padding: 1rem 0;
  }
  .navbar-expand-md .mega-menu-panel > .container-lg + .container-lg .nav-link:not(:only-child) {
    padding: 0 0 0.625rem;
    margin: 0 1.25rem 0.625rem 0;
    border-bottom: 0.0625rem solid #666;
  }
  .navbar-expand-md .mega-menu-panel .navbar-nav .navbar-nav .nav-link {
    padding: 0.3125rem 3.75rem 0.3125rem 0;
  }
  .navbar-expand-md .mega-menu-panel .back,
  .navbar-expand-md .mega-menu-panel .nav-heading {
    display: none;
  }
  .navbar-expand-md .mega-menu-panel .all {
    border: 0;
  }
  .navbar-expand-md.minimized .mega-menu-panel {
    top: 3.75rem;
  }
  .navbar-expand-md:not(.minimized) .mega-menu .nav-link {
    padding: 0.875rem 0.625rem;
  }
}

@media (min-width: 1024px) {
  .navbar-expand-lg .mega-menu {
    overflow: visible;
  }
  .navbar-expand-lg .mega-menu [data-toggle="collapse"] {
    display: flex;
  }
  .navbar-expand-lg .mega-menu [data-toggle="collapse"]::after {
    display: none;
  }
  .navbar-expand-lg .mega-menu [data-toggle="collapse"].active {
    position: relative;
  }
  .navbar-expand-lg .mega-menu [data-toggle="collapse"].active::before {
    right: 0.625rem;
    bottom: 0;
    left: 0.625rem;
    height: 0.25rem;
  }
  .navbar-expand-lg .mega-menu > .navbar-nav {
    transform: none;
    will-change: auto;
  }
  .navbar-expand-lg .mega-menu-panel {
    position: absolute;
    top: 6.25rem;
    left: 0;
    z-index: 1030;
    width: 100%;
    padding: 1.25rem 0 3.75rem;
    background-color: #000;
  }
  .navbar-expand-lg .mega-menu-panel .container-xl > .navbar-nav {
    display: flex;
    padding: 0 0.625rem;
  }
  .navbar-expand-lg .mega-menu-panel .col {
    padding: 1rem 0;
  }
  .navbar-expand-lg .mega-menu-panel > .container-xl + .container-xl .nav-link:not(:only-child) {
    padding: 0 0 0.625rem;
    margin: 0 1.25rem 0.625rem 0;
    border-bottom: 0.0625rem solid #666;
  }
  .navbar-expand-lg .mega-menu-panel .navbar-nav .navbar-nav .nav-link {
    padding: 0.3125rem 3.75rem 0.3125rem 0;
  }
  .navbar-expand-lg .mega-menu-panel .back,
  .navbar-expand-lg .mega-menu-panel .nav-heading {
    display: none;
  }
  .navbar-expand-lg .mega-menu-panel .all {
    border: 0;
  }
  .navbar-expand-lg.minimized .mega-menu-panel {
    top: 3.75rem;
  }
  .navbar-expand-lg:not(.minimized) .mega-menu .nav-link {
    padding: 0.875rem 0.625rem;
  }
}

@media (min-width: 1280px) {
  .navbar-expand-xl .mega-menu {
    overflow: visible;
  }
  .navbar-expand-xl .mega-menu [data-toggle="collapse"] {
    display: flex;
  }
  .navbar-expand-xl .mega-menu [data-toggle="collapse"]::after {
    display: none;
  }
  .navbar-expand-xl .mega-menu [data-toggle="collapse"].active {
    position: relative;
  }
  .navbar-expand-xl .mega-menu [data-toggle="collapse"].active::before {
    right: 0.625rem;
    bottom: 0;
    left: 0.625rem;
    height: 0.25rem;
  }
  .navbar-expand-xl .mega-menu > .navbar-nav {
    transform: none;
    will-change: auto;
  }
  .navbar-expand-xl .mega-menu-panel {
    position: absolute;
    top: 6.25rem;
    left: 0;
    z-index: 1030;
    width: 100%;
    padding: 1.25rem 0 3.75rem;
    background-color: #000;
  }
  .navbar-expand-xl .mega-menu-panel .container-xxl > .navbar-nav {
    display: flex;
    padding: 0 0.625rem;
  }
  .navbar-expand-xl .mega-menu-panel .col {
    padding: 1rem 0;
  }
  .navbar-expand-xl .mega-menu-panel > .container-xxl + .container-xxl .nav-link:not(:only-child) {
    padding: 0 0 0.625rem;
    margin: 0 1.25rem 0.625rem 0;
    border-bottom: 0.0625rem solid #666;
  }
  .navbar-expand-xl .mega-menu-panel .navbar-nav .navbar-nav .nav-link {
    padding: 0.3125rem 3.75rem 0.3125rem 0;
  }
  .navbar-expand-xl .mega-menu-panel .back,
  .navbar-expand-xl .mega-menu-panel .nav-heading {
    display: none;
  }
  .navbar-expand-xl .mega-menu-panel .all {
    border: 0;
  }
  .navbar-expand-xl.minimized .mega-menu-panel {
    top: 3.75rem;
  }
  .navbar-expand-xl:not(.minimized) .mega-menu .nav-link {
    padding: 0.875rem 0.625rem;
  }
}

@media (min-width: 1440px) {
  .navbar-expand-xxl .mega-menu {
    overflow: visible;
  }
  .navbar-expand-xxl .mega-menu [data-toggle="collapse"] {
    display: flex;
  }
  .navbar-expand-xxl .mega-menu [data-toggle="collapse"]::after {
    display: none;
  }
  .navbar-expand-xxl .mega-menu [data-toggle="collapse"].active {
    position: relative;
  }
  .navbar-expand-xxl .mega-menu [data-toggle="collapse"].active::before {
    right: 0.625rem;
    bottom: 0;
    left: 0.625rem;
    height: 0.25rem;
  }
  .navbar-expand-xxl .mega-menu > .navbar-nav {
    transform: none;
    will-change: auto;
  }
  .navbar-expand-xxl .mega-menu-panel {
    position: absolute;
    top: 6.25rem;
    left: 0;
    z-index: 1030;
    width: 100%;
    padding: 1.25rem 0 3.75rem;
    background-color: #000;
  }
  .navbar-expand-xxl .mega-menu-panel .container > .navbar-nav {
    display: flex;
    padding: 0 0.625rem;
  }
  .navbar-expand-xxl .mega-menu-panel .col {
    padding: 1rem 0;
  }
  .navbar-expand-xxl .mega-menu-panel > .container + .container .nav-link:not(:only-child) {
    padding: 0 0 0.625rem;
    margin: 0 1.25rem 0.625rem 0;
    border-bottom: 0.0625rem solid #666;
  }
  .navbar-expand-xxl .mega-menu-panel .navbar-nav .navbar-nav .nav-link {
    padding: 0.3125rem 3.75rem 0.3125rem 0;
  }
  .navbar-expand-xxl .mega-menu-panel .back,
  .navbar-expand-xxl .mega-menu-panel .nav-heading {
    display: none;
  }
  .navbar-expand-xxl .mega-menu-panel .all {
    border: 0;
  }
  .navbar-expand-xxl.minimized .mega-menu-panel {
    top: 3.75rem;
  }
  .navbar-expand-xxl:not(.minimized) .mega-menu .nav-link {
    padding: 0.875rem 0.625rem;
  }
}

.navbar-expand .mega-menu {
  overflow: visible;
}

.navbar-expand .mega-menu [data-toggle="collapse"] {
  display: flex;
}

.navbar-expand .mega-menu [data-toggle="collapse"]::after {
  display: none;
}

.navbar-expand .mega-menu [data-toggle="collapse"].active {
  position: relative;
}

.navbar-expand .mega-menu [data-toggle="collapse"].active::before {
  right: 0.625rem;
  bottom: 0;
  left: 0.625rem;
  height: 0.25rem;
}

.navbar-expand .mega-menu > .navbar-nav {
  transform: none;
  will-change: auto;
}

.navbar-expand .mega-menu-panel {
  position: absolute;
  top: 6.25rem;
  left: 0;
  z-index: 1030;
  width: 100%;
  padding: 1.25rem 0 3.75rem;
  background-color: #000;
}

.navbar-expand .mega-menu-panel .container > .navbar-nav {
  display: flex;
  padding: 0 0.625rem;
}

.navbar-expand .mega-menu-panel .col {
  padding: 1rem 0;
}

.navbar-expand .mega-menu-panel > .container + .container .nav-link:not(:only-child) {
  padding: 0 0 0.625rem;
  margin: 0 1.25rem 0.625rem 0;
  border-bottom: 0.0625rem solid #666;
}

.navbar-expand .mega-menu-panel .navbar-nav .navbar-nav .nav-link {
  padding: 0.3125rem 3.75rem 0.3125rem 0;
}

.navbar-expand .mega-menu-panel .back,
.navbar-expand .mega-menu-panel .nav-heading {
  display: none;
}

.navbar-expand .mega-menu-panel .all {
  border: 0;
}

.navbar-expand.minimized .mega-menu-panel {
  top: 3.75rem;
}

.navbar-expand:not(.minimized) .mega-menu .nav-link {
  padding: 0.875rem 0.625rem;
}

.supra {
  display: none;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 1px solid #666;
}

@media (min-width: 768px) {
  .supra {
    display: flex;
  }
}

.supra .navbar-nav {
  margin: 0 -0.46875rem;
}

.supra .nav-link {
  padding: 0.75rem 0.46875rem 0.8125rem;
  font-size: 0.875rem;
  line-height: 1;
}

.supra .nav-link [class*="icon-"] {
  font-size: 1rem;
}

.supra .nav-link.active::before {
  right: 0.46875rem;
  bottom: -0.0625rem;
  left: 0.46875rem;
}

.supra .nav-link[class*="svg-"] {
  padding: 0.25rem 0.625rem;
}

.supra .nav-link[class*="svg-"]::after {
  width: 1.5625rem;
  height: 1.5625rem;
}

.supra .dropdown-toggle {
  padding: 0.75rem 0.46875rem 0.8125rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1;
}

.supra .dropdown-toggle::after {
  border-width: 0.3125rem;
  transform: none;
}

.supra .dropdown-toggle [class*="icon-"] {
  font-size: 1rem;
}

.supra .nav-item + * > .nav-icon,
.supra .nav-item + * > .dropdown-toggle {
  margin-left: 0.625rem;
}

.supra .badge {
  top: 0;
  right: -0.3125rem;
}

.tablesorter th {
  padding-right: 1.875rem;
  cursor: pointer;
}

.tablesorter th a {
  color: #000;
  text-decoration: underline;
}

.tablesorter th a:hover, .tablesorter th a:focus {
  color: #f16e00;
  text-decoration: underline;
}

.tablesorter-header-inner span {
  display: flex;
}

.tablesorter-header-inner span::after {
  display: inline-block;
  align-self: center;
  width: 0.625rem;
  height: 0.375rem;
  margin-left: 0.375rem;
  content: "";
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 7'%3e%3cpath d='M7 7L0 0h14L7 7z'/%3e%3c/svg%3e") no-repeat right center/0.625rem 0.875rem;
}

[aria-sort="none"] .tablesorter-header-inner span::after {
  background-image: none;
}

.tablesorter-headerAsc .tablesorter-header-inner span::after {
  transform: rotate(180deg);
}

.tablesorter-headerUnSorted:hover span::after, .tablesorter-headerUnSorted:focus span::after {
  height: 1.0625rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 17'%3e%3cpath d='M 7,17 0,10 h 14 l -7,7 z'/%3e%3cpath d='M 7,0 14,7 0,7 7,0 z'/%3e%3c/svg%3e");
}

.o-tab-container {
  display: flex;
  flex-flow: column wrap;
  padding: 0 0.875rem;
  border-bottom: 0.0625rem solid #ccc;
}

.o-tab-content {
  display: none;
  padding: 0 1.5rem 0 0;
  margin: 0 0 1.25rem;
}

.o-tab-content.show {
  display: block;
}

.o-tab-content > :last-child {
  margin-bottom: 0;
}

.o-tab-heading {
  position: relative;
  display: flex;
  width: inherit;
  padding: 0.875rem;
  margin: inherit;
  font-weight: 700;
  text-decoration: none;
  border-top: 0.0625rem solid #ccc;
}

.o-tab-heading:hover {
  color: #ff7900;
}

.o-tab-heading:focus {
  z-index: 2;
  color: #000;
  outline: 0.125rem solid #000;
  outline-offset: 0;
}

.o-tab-heading[aria-expanded="true"] {
  color: #ff7900;
  background-color: #fff;
  outline-color: currentcolor;
}

@media (max-width: 767.98px) {
  .o-tab-container {
    width: calc(100% + 1.75rem);
    margin: 0 -0.875rem;
  }
  .o-tab-heading::after {
    display: block;
    align-self: center;
    width: 0.875rem;
    height: .4375rem;
    margin: 0 0.625rem 0 auto;
    content: "";
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 7'%3e%3cpath d='M7 7L0 0h14L7 7z'/%3e%3c/svg%3e") no-repeat 50%/0.875rem 1rem;
  }
  .o-tab-heading:hover::after, .o-tab-heading[aria-expanded="true"]::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 7'%3e%3cpath fill='%23f16e00' d='M7 7L0 0h14L7 7z'/%3e%3c/svg%3e");
  }
  .o-tab-heading[aria-expanded="true"]::after {
    transform: rotate(180deg);
  }
}

@media (min-width: 768px) {
  .o-tab-container {
    flex-direction: row;
    border: 0;
  }
  .o-tab-content {
    flex: 1 0 100%;
    order: 1;
    padding: 1.125rem;
    margin: 0;
    border: 0.125rem solid;
  }
  .o-tab-heading {
    padding: 0.875rem 1.875rem;
    margin-bottom: -0.125rem;
    font-weight: 700;
    border: 0.125rem solid transparent;
    border-bottom-color: #000;
  }
  .o-tab-heading ~ .o-tab-heading {
    margin-left: .5rem;
  }
  .o-tab-heading:hover, .o-tab-heading:focus, .o-tab-heading:active {
    color: #fff;
    background-color: #000;
  }
  .o-tab-heading:focus {
    outline-offset: -0.125rem;
  }
  .o-tab-heading[aria-expanded="true"] {
    color: #000;
    background-color: #fff;
    border-color: #000 #000 #fff #000;
  }
}

.o-nav-local {
  margin-bottom: 2.5rem;
  border-top: 0.0625rem solid #ccc;
  border-bottom: 0.0625rem solid #ccc;
}

.o-nav-local .nav-link {
  position: relative;
  padding: 0.875rem 1.25rem;
}

.o-nav-local .nav-link:focus {
  outline-offset: -0.125rem;
}

.o-nav-local .nav-link.active::before {
  position: absolute;
  right: 1.25rem;
  bottom: -0.0625rem;
  left: 1.25rem;
  height: 0.25rem;
  content: "";
  background-color: currentcolor;
}

.o-nav-local .nav > .nav-item:first-child .nav-link {
  padding-left: 0;
}

.o-nav-local .nav > .nav-item:first-child .active::before {
  left: 0;
}

.o-nav-local.navbar-dark {
  border-color: #333;
}

.skiplinks {
  position: fixed;
  top: 0;
  z-index: 1080;
}

.skiplinks a {
  outline-offset: 0.75rem;
  box-shadow: 0 0 0 0.25rem #000;
}

.skiplinks a:focus {
  font-weight: 700;
  color: #f16e00;
  background-color: #000;
  outline-offset: 0.25rem;
}

.o-footer {
  margin-top: 1.875rem;
  background-color: #000;
}

.o-footer * {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.125;
  color: #fff;
  letter-spacing: -0.00625rem;
}

.o-footer > * ~ :not(:last-child) {
  border-bottom: 0.0625rem solid #333;
}

.o-footer .nav-link {
  align-items: center;
  padding: 0.3125rem 0.625rem;
}

.o-footer .nav-link:hover, .o-footer .nav-link:focus {
  color: #f16e00;
}

.o-footer .nav-link [class*="icon-"] {
  margin-right: 0.3125rem;
  font-size: 1.875rem;
}

.o-footer .nav {
  margin: 0 -0.625rem;
}

.o-footer-top,
.o-footer-body {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}

.o-footer-bottom {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.o-footer-bottom * {
  font-size: 0.875rem;
}

@media (max-width: 1023.98px) {
  .o-responsive-table thead {
    display: none;
  }
  .o-responsive-table tbody,
  .o-responsive-table tr,
  .o-responsive-table th,
  .o-responsive-table td {
    display: block;
  }
}

@media (max-width: 1023.98px) and (min-width: 768px) {
  .o-responsive-table tr {
    float: left;
    width: 45%;
  }
  .o-responsive-table tr:nth-child(odd) {
    margin-right: 10%;
  }
}

@media (max-width: 1023.98px) {
  .o-responsive-table th,
  .o-responsive-table td {
    vertical-align: middle;
  }
  .o-responsive-table td[data-title]::before {
    float: left;
    width: 50%;
    font-weight: 700;
    content: attr(data-title);
  }
  .o-responsive-table tr {
    margin-bottom: 2em;
    border-right: 0;
    border-left: 0;
  }
  .o-responsive-table tbody th[scope="row"] {
    font-weight: 700;
    border-top: 0;
    border-bottom: 2px solid #ccc;
  }
}

.o-scroll-up {
  position: fixed;
  right: 0;
  bottom: 2.5rem;
  z-index: 1035;
  display: inline-flex;
  align-items: center;
  padding-left: 0.625rem;
  font-weight: 700;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.8);
  right: 1.25%;
  bottom: 0.25rem;
}

.o-scroll-up:hover {
  text-decoration: none;
}

@media (min-width: 480px) {
  .o-scroll-up {
    right: 1.25%;
    bottom: 0.375rem;
  }
}

@media (min-width: 768px) {
  .o-scroll-up {
    right: 1.5625%;
    bottom: 0.75rem;
  }
}

@media (min-width: 1024px) {
  .o-scroll-up {
    right: 3.125%;
    bottom: 2rem;
  }
}

@media (min-width: 1280px) {
  .o-scroll-up {
    right: 3.125%;
    bottom: 2.5rem;
  }
}

@media (min-width: 1440px) {
  .o-scroll-up {
    right: 4.16667%;
    bottom: 3.75rem;
  }
}

.o-scroll-up::after {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 0.625rem;
  content: "";
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 14'%3e%3cpath d='M9 2L7 0 0 7l7 7 2-2-5-5 5-5z'/%3e%3c/svg%3e") no-repeat center/50% 50%;
  border: 0.125rem solid #000;
  /* rtl:ignore */
  transform: rotate(90deg);
}

.o-scroll-up:hover::after, .o-scroll-up:focus::after {
  background: #000 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 14'%3e%3cpath fill='%23fff' d='M9 2L7 0 0 7l7 7 2-2-5-5 5-5z'/%3e%3c/svg%3e") no-repeat center/50% 50%;
  border-color: #000;
}

.o-scroll-up:active::after {
  background: #ff7900 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 14'%3e%3cpath d='M9 2L7 0 0 7l7 7 2-2-5-5 5-5z'/%3e%3c/svg%3e") no-repeat center/50% 50%;
  border-color: #ff7900;
}

.o-scroll-up.static {
  position: static;
  z-index: auto;
}

.swiper-button-prev,
.swiper-button-next {
  bottom: 0;
  z-index: 1;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  background: rgba(255, 255, 255, 0.5) no-repeat calc(50% - .125rem) 50%/1.5rem 1.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 14'%3e%3cpath d='M9 2L7 0 0 7l7 7 2-2-5-5 5-5z'/%3e%3c/svg%3e") !important;
  border-radius: 50%;
  transition: outline-offset 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .swiper-button-prev,
  .swiper-button-next {
    transition: none;
  }
}

.swiper-button-prev:hover, .swiper-button-prev:focus,
.swiper-button-next:hover,
.swiper-button-next:focus {
  background-color: #000;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 14'%3e%3cpath fill='%23fff' d='M9 2L7 0 0 7l7 7 2-2-5-5 5-5z'/%3e%3c/svg%3e") !important;
}

.swiper-button-prev:focus,
.swiper-button-next:focus {
  outline: 0.125rem solid #000;
  outline-offset: -0.125rem;
}

.js-focus-visible .swiper-button-prev:focus:not([data-focus-visible-added]), .js-focus-visible
.swiper-button-next:focus:not([data-focus-visible-added]) {
  outline: 0;
}

.swiper-button-prev:active,
.swiper-button-next:active {
  background-color: #ff7900;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 14'%3e%3cpath fill='%23666' d='M9 2L7 0 0 7l7 7 2-2-5-5 5-5z'/%3e%3c/svg%3e") !important;
  opacity: 1;
}

.swiper-button-prev {
  right: auto !important;
  left: 0.625rem !important;
  /* rtl:raw:
  transform: rotate(180deg);
  */
}

.swiper-button-prev::after {
  display: none;
}

.swiper-button-next {
  right: 0.625rem !important;
  left: auto !important;
  /* rtl:remove */
  transform: rotate(180deg);
}

.swiper-button-next::after {
  display: none;
}

.swiper {
  width: 100% !important;
  margin-bottom: 1.875rem;
  overflow: hidden;
}

.swiper .swiper-pagination {
  right: auto;
  bottom: 1rem;
  left: 50%;
  display: flex;
  justify-content: center;
  width: auto;
  padding: 0.375rem 0;
  background-color: rgba(255, 255, 255, 0.5);
  transform: translateX(-50%);
  border-radius: 1.25rem;
}

.swiper .swiper-pagination .swiper-pagination-bullet {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.375rem;
  margin-left: 0.375rem;
  background-clip: padding-box;
  border: 0.125rem solid transparent;
  opacity: 1;
}

.swiper .swiper-pagination .swiper-pagination-bullet:hover, .swiper .swiper-pagination .swiper-pagination-bullet:focus {
  border-color: #000;
}

.swiper .swiper-pagination .swiper-pagination-bullet-active {
  background-color: transparent;
  border-color: #000;
}

.swiper .swiper-pagination .swiper-pagination-bullet-active:hover, .swiper .swiper-pagination .swiper-pagination-bullet-active:focus {
  background-color: #000;
}

.swiper-slide .card {
  border: 0;
}

.swiper-slide .card-img {
  padding: 0;
}

.swiper-slide .card-img img {
  width: 100%;
  height: auto;
  max-height: 40vh;
  object-fit: cover;
}

@media (max-width: 1023.98px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
  .swiper {
    overflow-x: hidden;
    overflow-y: visible;
    /* used for displaying pagination outside of container */
  }
  .swiper-slide {
    width: 80%;
  }
  .swiper-slide .card-img img {
    height: auto;
  }
  .swiper-slide .card-body {
    position: static;
  }
}

/*# sourceMappingURL=boosted.css.map */