// Embedded icons from Open Iconic.
// Released under MIT and copyright 2014 Waybury.
// https://useiconic.com/open


// Checkboxes and radios
//
// Base class takes care of all the key behavioral aspects.

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: $font-size-base * $line-height-base;
  padding-left: $custom-control-gutter + $custom-control-indicator-size;
  margin-bottom: ($spacer * .5); // Boosted mod ensure that there's at least 10px
  print-color-adjust: exact; // Keep themed appearance for print
}

.custom-control-inline {
  display: inline-flex;
  margin-right: $custom-control-spacer-x;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1; // Put the input behind the label so it doesn't overlay text
  width: $custom-control-indicator-size;
  height: ($font-size-base * $line-height-base + $custom-control-indicator-size) * .5;
  opacity: 0;

  &:checked ~ .custom-control-label::before {
    color: $custom-control-indicator-checked-color;
    border-color: $custom-control-indicator-checked-border-color;
    @include gradient-bg($custom-control-indicator-checked-bg);
    @include box-shadow($custom-control-indicator-checked-box-shadow);
    // Boosted mod: move gradient to global settings, to change the color for disabled checked elements
    &::before {
      @include gradient-bg($custom-control-indicator-checked-bg);
    }
    // end mod
  }

  &:focus ~ .custom-control-label::before {
    // the mixin is not used here to make sure there is feedback
    outline: $border-width solid $black; // Boosted mod: no box-shadow, replaced by outline
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $custom-control-indicator-focus-box-shadow;
    } @else {
      box-shadow: $custom-control-indicator-focus-box-shadow;
    }
  }

  // Boosted mod
  .js-focus-visible &:focus:not([data-focus-visible-added]) ~ .custom-control-label::before {
    outline: 0;
  }
  // end mod

  //  &:focus:not(:checked) ~ .custom-control-label::before {
  //    border-color: $custom-control-indicator-focus-border-color;
  //  }

  &:not(:disabled):active ~ .custom-control-label::before {
    color: $custom-control-indicator-active-color;
    background-color: $custom-control-indicator-active-bg;
    border-color: $custom-control-indicator-active-border-color;
    @include box-shadow($custom-control-indicator-active-box-shadow);
  }

  // Use [disabled] and :disabled to work around https://github.com/twbs/bootstrap/issues/28247
  &[disabled],
  &:disabled {
    ~ .custom-control-label {
      color: $custom-control-label-disabled-color;

      &::before {
        // Boosted mod: our custom control have a border
        border-color: $custom-control-indicator-disabled-checked-bg;
        @include gradient-bg($custom-control-indicator-disabled-bg);
        // end mod
      }
    }
    // Boosted mod: disable bg only for checked elements
    &:checked ~ .custom-control-label {
      &::before {
        @include gradient-bg($custom-control-indicator-disabled-checked-bg);
      }
    }
  }
}

// Custom control indicators
//
// Build the custom controls out of pseudo-elements.

.custom-control-label {
  //position: relative; // Boosted mod: checkbox in table are misaligned
  margin-bottom: 0;
  color: $custom-control-label-color;
  vertical-align: top;
  cursor: $custom-control-cursor;

  // Background-color and (when enabled) gradient
  &::before {
    position: absolute;
    top: ($font-size-base * $line-height-base - $custom-control-indicator-size) * .5;
    left: 0; // Boosted mod: checkbox in tables are misaligned
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    pointer-events: none;
    content: "";
    background-color: $custom-control-indicator-bg;
    border: $custom-control-indicator-border-width solid $custom-control-indicator-border-color;
    @include box-shadow($custom-control-indicator-box-shadow);
  }

  // Foreground (icon)
  &::after {
    position: absolute;
    top: ($font-size-base * $line-height-base - $custom-control-indicator-size) * .5;
    left: 0; // Boosted mod: checkbox in tables are misaligned
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    content: "";
    background: 50% / #{$custom-control-indicator-bg-size} no-repeat;
  }
}


// Checkboxes
//
// Tweak just a few things for checkboxes.

.custom-checkbox {
  .custom-control-label::before {
    @include border-radius($custom-checkbox-indicator-border-radius);
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-image: escape-svg($custom-checkbox-indicator-icon-checked);
    }

    // Boosted mod
    &::before {
      border: $border-width solid $primary;
    }
    // End mod
  }

  // Boosted mod:no box-shadow, replaced by outline
  .custom-control-input:checked:focus ~ .custom-control-label::before {
    outline-color: $black;
  }

  .js-focus-visible .custom-control-input:checked:focus:not([data-focus-visible-added]) ~ .custom-control-label::before {
    outline: 0;
  }
  //end mod

  .custom-control-input:indeterminate ~ .custom-control-label {
    &::before {
      border-color: $custom-checkbox-indicator-indeterminate-border-color;
      @include gradient-bg($custom-checkbox-indicator-indeterminate-bg);
      @include box-shadow($custom-checkbox-indicator-indeterminate-box-shadow);
    }
    &::after {
      background-image: escape-svg($custom-checkbox-indicator-icon-indeterminate);
      background-size: map-get($spacers, 2) $border-width * 1.5;
      transform: translateY($border-width * .25);
    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label {
      // Boosted mod
      &::before {
        border-color: $custom-control-indicator-disabled-checked-bg;
      }

      &::after {
        background-image: escape-svg($custom-checkbox-indicator-icon-disabled);
      }
      // End mod
    }
  }
}

// Radios
//
// Tweak just a few things for radios.

.custom-radio {
  .custom-control-label::before {
    // stylelint-disable-next-line property-disallowed-list
    border-radius: $custom-radio-indicator-border-radius;
  }

  // Boosted mod move ::before gradient to global definition
  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      background-color: $black;
      border-color: $black;
    }

    &::after {
      border: 6px solid $primary;
      @include border-radius($custom-radio-indicator-border-radius, $custom-radio-indicator-border-radius);
    }
  }

  .custom-control-input:disabled:checked ~ .custom-control-label {
    &::after {
      border-color: $custom-control-indicator-disabled-checked-bg;
    }
  }
  //end mod

  // Boosted mod disable state manage globally
}


// switches
//
// Tweak a few things for switches

.custom-switch {
  padding-left: $custom-switch-width + $custom-control-gutter;

  // Boosted mod
  .custom-control-input {
    @include focus() {
      ~ .custom-control-label::after {
        outline: $border-width solid $white;
      }
    }

    .js-focus-visible &:focus:not([data-focus-visible-added]) ~ .custom-control-label::after {
      outline: 0;
    }
  }
  // end mod

  .custom-control-label {
    padding-top: map-get($spacers, 1); // Boosted mod: vertical align fix for label

    &::before {
      left: 0; // Boosted mod
      width: $custom-switch-width;
      height: $custom-switch-height; // Boosted mod
      pointer-events: all;
      background: $custom-switch-indicator-border-color escape-svg($close-icon-stroke-dark) no-repeat right .5rem top 50% / .75rem; // Boosted mod
      border-color: $custom-switch-indicator-border-color;
      // Boosted mod: not rounded
      // border-radius: $custom-switch-indicator-border-radius;
    }

    &::after {
      top: add(($font-size-base * $line-height-base - $custom-control-indicator-size) * .5, $custom-control-indicator-border-width);
      left: $custom-control-indicator-border-width; // Boosted mod
      width: $custom-switch-indicator-width; // Boosted mod
      height: $custom-switch-indicator-height; // Boosted mod
      background-color: $custom-switch-indicator-bg-color; // Boosted mod
      // Boosted mod : not rounded
      // border-radius: $custom-switch-indicator-border-radius;
      @include transition(transform .15s ease-in-out, $custom-forms-transition);
    }
  }
  // Boosted mod: create a right aligned switch
  &.right {
    padding-right: $custom-switch-width + $custom-control-gutter;
    padding-left: 0;
    .custom-control-label {
      &::before {
        right: 0;
        left: auto;
      }

      &::after {
        right: add($custom-switch-width * .5, $custom-control-indicator-border-width);
        left: auto;
      }
    }
  }
  // end mod
  .custom-control-input:checked ~ .custom-control-label {
    // Boosted mod
    &::before {
      background: $custom-control-indicator-checked-bg escape-svg($custom-checkbox-indicator-icon-checked) no-repeat left .375rem top 50% / #{$custom-control-indicator-bg-size};
    }
    // end mod
    &::after {
      background-color: $custom-control-indicator-bg;
      transform: translateX($custom-switch-width * .5); // Boosted mod : change the indicator size
    }
  }

  .custom-control-input:disabled {
    ~ .custom-control-label::before,
    &:checked ~ .custom-control-label::before {
      // Boosted mod
      @include gradient-bg($custom-control-indicator-disabled-checked-bg);
      background-image: escape-svg($custom-checkbox-indicator-icon-disabled);
      // End mod
    }
  }
}


// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.
//

.custom-select {
  display: inline-block;
  width: 100%;
  height: $custom-select-height;
  padding: $custom-select-padding-y ($custom-select-padding-x + $custom-select-indicator-padding) $custom-select-padding-y $custom-select-padding-x;
  font-family: $custom-select-font-family;
  @include font-size($custom-select-font-size);
  font-weight: $custom-select-font-weight;
  line-height: $custom-select-line-height;
  color: $custom-select-color;
  vertical-align: middle;
  background: $custom-select-bg $custom-select-background;
  border: $custom-select-border-width solid $custom-select-border-color;
  @include border-radius($custom-select-border-radius, 0);
  //@include box-shadow($custom-select-box-shadow); // Boosted mod no box-shadow
  appearance: none;

  &:focus {
    border-color: $custom-select-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      @include box-shadow($custom-select-box-shadow, $custom-select-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $custom-select-focus-box-shadow;
    }

    &::-ms-value {
      // For visual consistency with other platforms/browsers,
      // suppress the default white text on blue background highlight given to
      // the selected option text when the (still closed) <select> receives focus
      // in IE and (under certain conditions) Edge.
      // See https://github.com/twbs/bootstrap/issues/19398.
      color: $input-color;
      background-color: $input-bg;
    }
  }

  &[multiple],
  &[size]:not([size="1"]) {
    height: auto;
    padding-right: $custom-select-padding-x;
    background-image: none;
  }

  &:disabled {
    color: $custom-select-disabled-color;
    background-color: $custom-select-disabled-bg;
    background-image: escape-svg($custom-select-disabled-indicator);
  }

  // Hides the default caret in IE11
  &::-ms-expand {
    display: none;
  }

  // Remove outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $custom-select-color;
  }
}

.custom-select-sm {
  height: $custom-select-height-sm;
  padding-top: $custom-select-padding-y-sm;
  padding-bottom: $custom-select-padding-y-sm;
  padding-left: $custom-select-padding-x-sm;
  @include font-size($custom-select-font-size-sm);
}

.custom-select-lg {
  height: $custom-select-height-lg;
  padding-top: $custom-select-padding-y-lg;
  padding-bottom: $custom-select-padding-y-lg;
  padding-left: $custom-select-padding-x-lg;
  @include font-size($custom-select-font-size-lg);
}


// File
//
// Custom file input.

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: $custom-file-height;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: $custom-file-height;
  margin: 0;
  overflow: hidden;
  opacity: 0;

  &:focus ~ .custom-file-label {
    border-color: $custom-file-focus-border-color;
    box-shadow: $custom-file-focus-box-shadow;
  }

  // Use [disabled] and :disabled to work around https://github.com/twbs/bootstrap/issues/28247
  &[disabled] ~ .custom-file-label,
  &:disabled ~ .custom-file-label {
    background-color: $custom-file-disabled-bg;
  }

  @each $lang, $value in $custom-file-text {
    &:lang(#{$lang}) ~ .custom-file-label::after {
      content: $value;
    }
  }

  ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse);
  }
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: $custom-file-height;
  padding: $custom-file-padding-y $custom-file-padding-x;
  overflow: hidden;
  font-family: $custom-file-font-family;
  font-weight: $custom-file-font-weight;
  line-height: $custom-file-line-height;
  color: $custom-file-color;
  background-color: $custom-file-bg;
  border: $custom-file-border-width solid $custom-file-border-color;
  @include border-radius($custom-file-border-radius);
  @include box-shadow($custom-file-box-shadow);

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: $custom-file-height-inner;
    padding: $custom-file-padding-y $custom-file-padding-x;
    line-height: $custom-file-line-height;
    color: $custom-file-button-color;
    content: "Browse";
    @include gradient-bg($custom-file-button-bg);
    border-left: inherit;
    @include border-radius(0 $custom-file-border-radius $custom-file-border-radius 0);
  }
}

// Range
//
// Style range inputs the same across browsers. Vendor-specific rules for pseudo
// elements cannot be mixed. As such, there are no shared styles for focus or
// active states on prefixed selectors.

.custom-range {
  width: 100%;
  height: add($custom-range-thumb-height, $custom-range-thumb-focus-box-shadow-width * 2);
  padding: 0; // Need to reset padding
  background-color: transparent;
  outline-offset: $border-width * 4; // Boosted mod
  appearance: none;
  @include transition($transition-focus);

  &:focus {
    // Boosted mod: better focus visibility
    outline: $custom-range-thumb-border;
    outline-offset: $border-width;
    // end mod

    // Pseudo-elements must be split across multiple rulesets to have an effect.
    // No box-shadow() mixin for focus accessibility.
    &::-webkit-slider-thumb {
      border-color: $custom-range-track-progress-bg;
      box-shadow: $custom-range-thumb-focus-box-shadow;
    }
    &::-moz-range-thumb {
      border-color: $custom-range-track-progress-bg;
      box-shadow: $custom-range-thumb-focus-box-shadow;
    }
    &::-ms-thumb {
      border-color: $custom-range-track-progress-bg;
      box-shadow: $custom-range-thumb-focus-box-shadow;
    }
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-thumb {
    box-sizing: content-box; // Boosted mod
    width: $custom-range-thumb-width;
    height: $custom-range-thumb-height;
    margin-top: #{add(-$custom-range-thumb-height * .5, 1px)}; // Webkit specific // Boosted mod
    cursor: grab; // Boosted mod
    @include gradient-bg($custom-range-thumb-bg);
    border: $custom-range-thumb-border;
    @include border-radius($custom-range-thumb-border-radius, $custom-range-thumb-border-radius); // Boosted mod: always rounded
    @include box-shadow($custom-range-thumb-box-shadow);
    // @include transition($custom-forms-transition); // Boosted mod
    appearance: none;

    &:active {
      cursor: grabbing; // Boosted mod
      // @include gradient-bg($custom-range-thumb-active-bg); // Boosted mod
    }
  }

  &::-webkit-slider-runnable-track {
    width: $custom-range-track-width;
    height: $custom-range-track-height;
    color: transparent; // Why?
    cursor: $custom-range-track-cursor;
    background-color: $custom-range-track-bg;
    border-color: transparent;
    @include border-radius($custom-range-track-border-radius);
    @include box-shadow($custom-range-track-box-shadow);
  }

  &::-moz-range-thumb {
    width: $custom-range-thumb-width;
    height: $custom-range-thumb-height;
    cursor: grab; // Boosted mod
    @include gradient-bg($custom-range-thumb-bg);
    border: $custom-range-thumb-border;
    @include border-radius($custom-range-thumb-border-radius, $custom-range-thumb-border-radius); // Boosted mod: always rounded
    @include box-shadow($custom-range-thumb-box-shadow);
    // @include transition($custom-forms-transition); // Boosted mod
    appearance: none;

    &:active {
      cursor: grabbing; // Boosted mod
      // @include gradient-bg($custom-range-thumb-active-bg); // Boosted mod
    }
  }

  &::-moz-range-track {
    width: $custom-range-track-width;
    height: $custom-range-track-height;
    color: transparent;
    cursor: $custom-range-track-cursor;
    background-color: $custom-range-track-bg;
    border-color: transparent; // Firefox specific?
    @include border-radius($custom-range-track-border-radius);
    @include box-shadow($custom-range-track-box-shadow);
  }

  // Boosted mod: the same as ::-ms-fill-lower
  // @see https://developer.mozilla.org/fr/docs/Web/CSS/::-moz-range-progress
  &::-moz-range-progress {
    height: $custom-range-track-height;
    background-color: $custom-range-track-progress-bg;
    @include border-radius($custom-range-track-border-radius);
  }
  // end mod

  &::-ms-thumb {
    width: $custom-range-thumb-width;
    height: $custom-range-thumb-height;
    margin-top: 0; // Edge specific
    margin-right: $custom-range-thumb-focus-box-shadow-width; // Workaround that overflowed box-shadow is hidden.
    margin-left: $custom-range-thumb-focus-box-shadow-width;  // Workaround that overflowed box-shadow is hidden.
    cursor: grab; // Boosted mod
    @include gradient-bg($custom-range-thumb-bg);
    border: $custom-range-thumb-border;
    @include border-radius($custom-range-thumb-border-radius, $custom-range-thumb-border-radius); // Boosted mod: always rounded
    @include box-shadow($custom-range-thumb-box-shadow);
    // @include transition($custom-forms-transition); // Boosted mod
    appearance: none;

    &:active {
      cursor: grabbing; // Boosted mod
      // @include gradient-bg($custom-range-thumb-active-bg); // Boosted mod
    }
  }

  &::-ms-track {
    width: $custom-range-track-width;
    height: $custom-range-track-height;
    color: transparent;
    cursor: $custom-range-track-cursor;
    background-color: transparent;
    border-color: transparent;
    border-width: $custom-range-thumb-height * .5;
    @include box-shadow($custom-range-track-box-shadow);
  }

  &::-ms-fill-lower {
    background-color: $custom-range-track-progress-bg; // Boosted mod: show lower part
    @include border-radius($custom-range-track-border-radius);
  }

  &::-ms-fill-upper {
    margin-right: 15px; // arbitrary?
    background-color: $custom-range-track-bg;
    @include border-radius($custom-range-track-border-radius);
  }

  &:disabled {
    &::-webkit-slider-thumb {
      cursor: default; // Boosted mod
      border-color: $custom-range-thumb-disabled-bg; // Boosted mod
    }

    &::-webkit-slider-runnable-track {
      cursor: default;
    }

    &::-moz-range-thumb {
      cursor: default; // Boosted mod
      border-color: $custom-range-thumb-disabled-bg; // Boosted mod
    }

    &::-moz-range-track {
      cursor: default;
    }

    // Boosted mod
    &::-moz-range-progress {
      background-color: $custom-range-thumb-disabled-bg;
    }
    // end mod

    &::-ms-thumb {
      cursor: default; // Boosted mod
      border-color: $custom-range-thumb-disabled-bg; // Boosted mod
    }

    // Boosted mod
    &::-ms-fill-lower {
      background-color: $custom-range-thumb-disabled-bg;
    }
    // end mod
  }
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  @include transition($custom-forms-transition);
}
