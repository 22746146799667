.close {
  float: right;
  // Boosted mod
  width: $close-icon-size;
  height: $close-icon-size;
  padding: 0;
  margin-right: $close-icon-offset;
  @include transition($transition-focus);
  background: escape-svg($close-icon-stroke) no-repeat 50% / 1rem;
  border: 0;
  outline-offset: $close-icon-offset;
  opacity: 1;

  .bg-dark &,
  .navbar-dark & {
    background-image: escape-svg($close-icon-stroke-dark);

    &:hover,
    &:focus {
      outline-color: $gray-700;
    }
  }

  &:hover,
  &:focus {
    outline: $border-width solid $gray-500;
    outline-offset: -#{$border-width};

    &:not(:disabled):not(.disabled) {
      opacity: 1;
    }
  }

  &:hover {
    @include transition(none);
  }
  // End mod
}

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

// Boosted mod: included above ↑

// Future-proof disabling of clicks on `<a>` elements

// stylelint-disable-next-line selector-no-qualifying-type
a.close.disabled {
  pointer-events: none;
}
