//
// Orange stepbar
//
.o-stepbar {
  ol {
    display: flex;
    padding: 0;
    margin: 0;
    overflow: hidden;
    counter-reset: step;
  }
}

.stepbar-item {
  display: flex;
  flex: 1;
  padding: $o-stepbar-padding-y $o-stepbar-padding-x;
  margin-right: $o-stepbar-margin-r;
  font-weight: $font-weight-bold;
  text-align: center;
  list-style: none;
  counter-increment: step;
  background-color: $black;
}

.current {
  background-color: $primary;

  .stepbar-link {
    &,
    &:hover,
    &:focus,
    &:active {
      color: $black;
    }
  }

  ~ .stepbar-item {
    background-color: $gray-400;

    .stepbar-link {
      &,
      &:hover,
      &:focus,
      &:active {
        color: $black;
      }
    }
  }
}

.stepbar-link {
  flex: 1 0 1.25ch;
  max-width: 1.25ch;
  margin: auto;
  overflow: hidden;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  color: $white;
  text-decoration: if($link-decoration == none, null, none);
  white-space: nowrap;
  outline-offset: map-get($spacers, 3);

  &::before {
    content: counter(step) "\A0";
  }

  &:hover,
  &:focus,
  &:active {
    color: $white;
  }

  &:hover {
    text-decoration: if($link-hover-decoration == underline, null, underline);
  }

  &:focus {
    text-decoration: $link-decoration;
    outline-offset: .0625rem;
  }
}

@include media-breakpoint-up(sm) {
  .stepbar-item {
    position: relative;
    max-width: 3.75rem;
    padding: $o-stepbar-padding-y .25rem $o-stepbar-padding-y add($o-stepbar-arrow-width, .25rem);
    margin: 0;

    &::after {
      position: absolute;
      top: -1px;
      right: 1px;
      z-index: 2;
      width: $o-stepbar-arrow-width;
      height: $o-stepbar-height;
      content: "";
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 40'><path fill='#{$black}' d='M0 0l14 20L0 40z'/><path fill='#{$white}' d='M3 0H0l14 20L0 40h3l14-20z'/></svg>"));
      background-size: $o-stepbar-arrow-width $o-stepbar-height;
      transform: translateX(#{"/*rtl:insert:-*/"}$o-stepbar-arrow-width) #{"/*rtl:insert:rotate(180deg)*/"};
    }

    &:first-child {
      padding-left: $o-stepbar-padding-x-xc;
    }

    &:last-child {
      padding-right: $o-stepbar-padding-x-xc;
    }

    &:last-child::after {
      display: none;
    }
  }

  .current,
  .current .stepbar-link {
    max-width: none;
  }

  .current::after {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 40'><path fill='#{$primary}' d='M0 0l14 20L0 40z'/><path fill='#{$white}' d='M3 0H0l14 20L0 40h3l14-20z'/></svg>"));
  }

  .current ~ .stepbar-item {
    &::after {
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 40'><path fill='#{$gray-400}' d='M0 0l14 20L0 40z'/><path fill='#{$white}' d='M3 0H0l14 20L0 40h3l14-20z'/></svg>"));
    }
  }

  .current .stepbar-link::before {
    content: counter(step) ".\A0";
  }
}

@include media-breakpoint-up(xl) {
  .stepbar-item,
  .stepbar-link {
    max-width: none;
  }

  .stepbar-link::before {
    content: counter(step) ".\A0";
  }
}
